export default function vuexTabSync (store) {
    let updating = false;
    const ID = `Antares-${Date.now()}-${Math.random()}`; // Identifier used to prevent Tab/Window from re-updating itself
    const globalObj = window !== "undefined" ? window : global; // global may likely not be needed
  
    // Broadcast triggers; should be the name of a mutation
    const triggers = [
      "setUser",
      "setCart",
      "setLang",
      "setConsentStatus"
    ];
  
    /**
     * Common functionality between strategies. Parse Stringified JSON data & updates Tab/Window.
     * 
     * Skips Tab/Window if recepient is the same as the sender
     * @param {string} event Stringified JSON data from the triggering event
     */
    const cb = (event) => {
      try {
        const mutation = JSON.parse(event);
  
        if (mutation.author !== ID) {
          updating = true;
          store.commit(mutation.type, mutation.payload);
        }
      } catch (e) {
        console.log(e);
      } finally {
        updating = false;
      }
    }
  
    /**
     * Content broadcast strategy using BroadcastChannel. Creates a Broadcast Channel instance, sets up an event listener, and returns a generated broadcasting function
     * @returns {function} broadcast function utilizing BroadcastChannel API
     */
    const broadcastChannelStrategy = () => {
      const channel = new BroadcastChannel("antarestech");
  
      channel.addEventListener("message", e => {
        cb(e.data);
      });
  
      return (message) => {
        channel.postMessage(JSON.stringify(message));
      }
    }
  
    /**
     * Content broadcast strategy using LocalStorage. Creates an event listener on the browser window (or global object if no window is available), attaches an event listener, and returns a generated broadcasting function
     * @returns {function} broadcast function utilizing LocalStorage
     */
    const localStorageStrategy = () => {
      const stateDeltasKey = "Antarestech";
      const globalObj = window !== "undefined" ? window : global; // global may likely not be needed; Headless (NodeJS) only?
      const fragments = {};
      
      globalObj.window.addEventListener("storage", event => {
        if (!event.newValue) {
          return;
        }
  
        if (event.key !== stateDeltasKey) {
          return;
        }
  
        try {
          const messagePart = JSON.parse(event.newValue);
  
          if (messagePart.author !== ID) {
            let messageObj = fragments[messagePart.messageID];
  
            if (!messageObj) {
              messageObj = {
                totalParts: messagePart.totalParts,
                parts: []
              }
            }
  
            messageObj.parts[messagePart.part] = messagePart.content;
  
            // Check if we have received all message fragments
            if (messageObj.parts.length === messageObj.totalParts) {
              const fullMessage = messageObj.parts.join('');
              messageObj = null; // Clean up message
              cb(fullMessage);
            }
  
            fragments[messagePart.messageID] = messageObj;
          }
        } catch (e) {
          console.warn(e);
        }
      })
  
      return (message) => {
        // Send data to LocalStorage just to trigger an Event on Storage & immediately remove it to avoid polluting LocalStorage
        const author = message.author; // Need to pull out to help prevent same page from attempting to process the same parts it is about to broadcast
        const messageID = `${author}___${Date.now()}`;
        const stringifiedMessage = JSON.stringify(message);
        const maxContentSize = 1024 * 10; // 10KB
        const totalParts = Math.ceil(stringifiedMessage.length / maxContentSize);
  
        const broadcastHelper = (message) => {
          try {
            globalObj.localStorage.setItem(stateDeltasKey, message);
            globalObj.localStorage.removeItem(stateDeltasKey);
          } catch (e) {
            console.warn("Unable to broadcast message fragment");
          }
        }
  
        for (let i = 0; i < totalParts; i++) {
          let content = stringifiedMessage.substring(i * maxContentSize, (i + 1) * maxContentSize);
          let messagePart = JSON.stringify({
            author,
            messageID,
            totalParts,
            part: i,
            content
          });
  
          broadcastHelper(messagePart);
        }
      }
    }
  
    // Define the broadcasting strategy by determining browser support for BroadcastChannel
    const broadcast = typeof globalObj.BroadcastChannel === "function" ? broadcastChannelStrategy() : localStorageStrategy();
  
    store.subscribe((mutation) => {
      // Prevent Tab/Window from generating more messages while it updates from a received message
      if (updating) {
        return;
      }
  
      if (triggers.includes(mutation.type)) {
        let message = {...mutation, author: ID };
        broadcast (message);
      }
    });
}
