import _typeof from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.error.cause.js";
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["disabled", "href"];
import { inject, computed } from 'vue';
import { useStore } from 'vuex';
import { getFsDataObj } from '@/helpers';
import { useFsLoading } from '@/classes/FastSpring';
export default {
  props: {
    class: String,
    customClass: String,
    // Deprecated; going forward, please use the 'class' prop above
    type: {
      'type': String,
      'default': '',
      validator: function validator(v) {
        return ["", "primary", "success", "warning", "danger", "info"].includes(v === null || v === void 0 ? void 0 : v.toLowerCase());
      }
    },
    size: {
      'type': String,
      'default': 'default',
      validator: function validator(v) {
        return ["large", "default", "small"].includes(v === null || v === void 0 ? void 0 : v.toLowerCase());
      }
    },
    plain: Boolean,
    round: Boolean,
    circle: Boolean,
    disabled: Boolean,
    loading: Boolean,
    bg: Boolean,
    action: {
      'type': String,
      'default': 'add_to_cart',
      validator: function validator(v) {
        return ["add_to_cart", "go_to", "go_to_external", "open_trial_modal", "open_product_modal", "open_specific_products_modal", "handle_upgrade", "open_popup"].includes(v === null || v === void 0 ? void 0 : v.toLowerCase());
      }
    },
    value: {
      required: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var user = inject('user');
    var cart = inject('cart');
    var openProductOptions = inject("openProductOptions", function () {});
    var openSpecificProductOptions = inject("openSpecificProductOptions", function () {});
    var openTrialModal = inject("openTrialModal", function () {});
    var openPopupModal = inject("openPopupModal", function () {}); // Search for a provided CB; default to empty function if none is found

    var cb = inject('callback', function () {});
    var fsLoading = useFsLoading();
    var isLoading = computed(function () {
      var usesFSData = !["go_to", "go_to_external", "open_popup"].includes(props.action);
      return props.loading || usesFSData && fsLoading.value;
    });
    var isDisabled = computed(function () {
      return props.disabled || isLoading.value;
    });
    var fsData = computed(function () {
      return store.getters.getFsData;
    });
    /**
     * Handles the button click.
     */

    var handleClick = function handleClick() {
      var upgradeHandlerData = {
        path: null,
        followup: false
      };

      switch (props.action) {
        case 'add_to_cart':
          // Value must be a SKU
          try {
            cart.handleAddToCartBtnClick(props.value);
          } catch (e) {
            console.warn(e);
          }

          break;

        case 'open_product_modal':
          // Value must be a SKU
          openProductOptions(getFsDataObj(fsData.value, props.value));
          break;

        case 'open_specific_products_modal':
          try {
            if (!props.value) {
              throw new Error("Missing value property");
            }

            if (_typeof(props.value) !== "object") {
              throw new Error("Unexcepted value type; expected Object, received value of type \"".concat(_typeof(props.value), "\""));
            }

            openSpecificProductOptions(props.value);
          } catch (e) {
            console.warn(e);
          }

          break;

        case 'open_trial_modal':
          // Does not need a value
          openTrialModal();
          break;

        case "handle_upgrade":
          if (typeof props.value === "string") {
            upgradeHandlerData.path = props.value;
          } else {
            upgradeHandlerData = props.value;
          }

          if (!upgradeHandlerData.path) {
            throw new Error("Button is misconfigured");
          }

          if (!(user !== null && user !== void 0 && user.id) && !upgradeHandlerData.followup) {
            // User is not signed in; show the Product Options Modal for Marketing purposes
            openSpecificProductOptions({
              opts: [upgradeHandlerData.path]
            });
          } else {
            // Attempt to add the Upgrade to the cart
            cart.handleUpgradeCtaClick(upgradeHandlerData.path);
          }

          break;

        case "open_popup":
          // Opens the Marketing Popup; does not allow for nexted marketing popups
          openPopupModal(props.value);
          break;
      }

      cb(); // Execute extra functionality if callback was injected in earlier
    };

    return function (_ctx, _cache) {
      var _props$action, _props$type, _props$size, _props$action2, _props$type3, _props$size3;

      var _component_el_button = _resolveComponent("el-button");

      var _component_router_link = _resolveComponent("router-link");

      return ((_props$action = __props.action) === null || _props$action === void 0 ? void 0 : _props$action.toLowerCase()) === 'go_to_external' ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        disabled: _unref(isDisabled),
        href: __props.value,
        target: "_blank"
      }, [_createVNode(_component_el_button, {
        disabled: _unref(isDisabled),
        loading: _unref(isLoading),
        class: _normalizeClass(["fill-container", props.class || __props.customClass]),
        type: (_props$type = __props.type) === null || _props$type === void 0 ? void 0 : _props$type.toLowerCase(),
        plain: __props.plain,
        round: __props.round,
        circle: __props.circle,
        bg: __props.bg,
        size: (_props$size = __props.size) === null || _props$size === void 0 ? void 0 : _props$size.toLowerCase()
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"])], 8, _hoisted_1)) : ((_props$action2 = __props.action) === null || _props$action2 === void 0 ? void 0 : _props$action2.toLowerCase()) === 'go_to' ? (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        disabled: _unref(isDisabled),
        to: __props.value,
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return handleClick();
        })
      }, {
        default: _withCtx(function () {
          var _props$type2, _props$size2;

          return [_createVNode(_component_el_button, {
            disabled: _unref(isDisabled),
            loading: _unref(isLoading),
            class: _normalizeClass(["fill-container", props.class || __props.customClass]),
            type: (_props$type2 = __props.type) === null || _props$type2 === void 0 ? void 0 : _props$type2.toLowerCase(),
            plain: __props.plain,
            round: __props.round,
            circle: __props.circle,
            bg: __props.bg,
            size: (_props$size2 = __props.size) === null || _props$size2 === void 0 ? void 0 : _props$size2.toLowerCase()
          }, {
            default: _withCtx(function () {
              return [_renderSlot(_ctx.$slots, "default")];
            }),
            _: 3
          }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"])];
        }),
        _: 3
      }, 8, ["disabled", "to"])) : (_openBlock(), _createBlock(_component_el_button, {
        key: 2,
        disabled: _unref(isDisabled),
        loading: _unref(isLoading),
        class: _normalizeClass(props.class || __props.customClass),
        type: (_props$type3 = __props.type) === null || _props$type3 === void 0 ? void 0 : _props$type3.toLowerCase(),
        plain: __props.plain,
        round: __props.round,
        circle: __props.circle,
        bg: __props.bg,
        size: (_props$size3 = __props.size) === null || _props$size3 === void 0 ? void 0 : _props$size3.toLowerCase(),
        onClick: handleClick
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["disabled", "loading", "class", "type", "plain", "round", "circle", "bg", "size"]));
    };
  }
};