import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3d83b4f4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "flex-col fill-container",
  style: {
    "align-items": "center",
    "justify-content": "space-evenly"
  }
};
var _hoisted_2 = {
  key: 0,
  class: "fill-container",
  style: {
    "padding": "10px",
    "box-sizing": "border-box"
  }
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 1,
  notranslate: ""
};
var _hoisted_5 = {
  class: "label"
};
var _hoisted_6 = ["href"];
var _hoisted_7 = {
  class: "flex-col fill-container",
  style: {
    "align-items": "center",
    "justify-content": "space-evenly"
  }
};
var _hoisted_8 = {
  key: 0,
  class: "fill-container",
  style: {
    "padding": "10px",
    "box-sizing": "border-box"
  }
};
var _hoisted_9 = ["src"];
var _hoisted_10 = {
  key: 1,
  notranslate: ""
};
var _hoisted_11 = {
  class: "label"
};
var _hoisted_12 = {
  class: "flex-col fill-container",
  style: {
    "align-items": "center",
    "justify-content": "space-evenly"
  }
};
var _hoisted_13 = {
  key: 0,
  class: "fill-container",
  style: {
    "padding": "10px",
    "box-sizing": "border-box"
  }
};
var _hoisted_14 = ["src"];
var _hoisted_15 = {
  key: 1,
  notranslate: ""
};
var _hoisted_16 = {
  class: "label"
};
export default {
  props: {
    name: String,
    router_link: Object,
    external_link: String,
    icon_letter: String,
    icon_link: String,
    active: Boolean,
    menuItem: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_router_link = _resolveComponent("router-link");

      return __props.router_link ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(["container link", {
          active: __props.active,
          menuItem: __props.menuItem
        }]),
        to: __props.router_link
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
            justify: "center",
            align: "middle",
            class: "icon"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", {
                class: "overlayed-container flex-col flex-centered icon_content",
                style: _normalizeStyle("font-size:".concat(16 - 2 * (__props.icon_letter.split('').length - 1), "px"))
              }, [__props.icon_link ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
                class: "fill-container",
                src: __props.icon_link
              }, null, 8, _hoisted_3)])) : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(__props.icon_letter), 1))], 4)];
            }),
            _: 1
          }), __props.name ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            justify: "center",
            align: "top"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("span", _hoisted_5, _toDisplayString(__props.name), 1)];
            }),
            _: 1
          })) : _createCommentVNode("", true)])];
        }),
        _: 1
      }, 8, ["to", "class"])) : __props.external_link ? (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: __props.external_link,
        target: "_blank",
        class: _normalizeClass(["container link", {
          active: __props.active,
          menuItem: __props.menuItem
        }])
      }, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_row, {
        justify: "center",
        align: "middle",
        class: "icon"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "overlayed-container flex-col flex-centered icon_content",
            style: _normalizeStyle("font-size:".concat(16 - 2 * (__props.icon_letter.split('').length - 1), "px"))
          }, [__props.icon_link ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("img", {
            class: "fill-container",
            src: __props.icon_link
          }, null, 8, _hoisted_9)])) : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(__props.icon_letter), 1))], 4)];
        }),
        _: 1
      }), __props.name ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        justify: "center",
        align: "top"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_11, _toDisplayString(__props.name), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true)])], 10, _hoisted_6)) : (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: _normalizeClass(["container", {
          active: __props.active,
          menuItem: __props.menuItem
        }])
      }, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_el_row, {
        justify: "center",
        align: "middle",
        class: "icon"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "overlayed-container flex-col flex-centered icon_content",
            style: _normalizeStyle("font-size:".concat(16 - 2 * (__props.icon_letter.split('').length - 1), "px"))
          }, [__props.icon_link ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("img", {
            class: "fill-container",
            src: __props.icon_link
          }, null, 8, _hoisted_14)])) : (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(__props.icon_letter), 1))], 4)];
        }),
        _: 1
      }), __props.name ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        justify: "center",
        align: "top"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_16, _toDisplayString(__props.name), 1)];
        }),
        _: 1
      })) : _createCommentVNode("", true)])], 2));
    };
  }
};