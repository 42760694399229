import { toRaw } from "vue";
import langs from '@/langs.json';
import dictionary from '@/router/generator/dictionary.json';
import router from '@/router';
import slugs from "@/router/slugs.json";

const slugsDict = {};

// Convert slugs.json into schema similar to dictionary.json
Object.values(slugs).forEach( page => {
    page.forEach(slug => {
        if (!(slug && typeof slug === "object")) {
            return;
        }

        if (!slug?.en) {
            return;
        }

        const slugEntries = { ...slug };

        delete slugEntries.en;

        if (Object.keys(slugEntries)?.length) {
            slugsDict[slug.en] = slugEntries;
        }
    });
});

/**
 * Retrieves the dictionary entry for the provided phrase;
 * contains all non-English translations for the phrase
 * 
 * @param {String} phrase 
 * @returns 
 */
export function getEntry (phrase) {
    return dictionary[phrase];
}

/**
 * Retrieve a translation for the provided phrase, if one exists, for the given language.
 * 
 * Returns the original phrase if no translation can be found, the target language is not in the list of enabled languages, or the target language matches the source language
 * 
 * @param {String} phrase source phrase to be translated
 * @param {String} lang language code used for desired translation
 * @param {String} source language code; defaults to EN
 * @param {Object|Null} entry optional; dictionary entry to use
 * @returns {String}
 */
export function getTranslation (phrase, lang = "en", source = "en", entry) {
    if (!lang) {
        return phrase;
    }

    if (!langs.includes(source)) {
        console.error("Provided source language code is not supported");
        return phrase;
    }

    if (!langs.includes(lang)) {
        console.error("Provided target language code is not supported");
        return phrase;
    }

    if (typeof phrase !== "string") {
        throw new Error("Invalid phrase passed in");
    }

    if (source === lang) {
        return phrase;
    }

    // Reverse lookup for English phrase
    if (lang === "en") {
        const checkSource = (source) => {
            for (const [enPhrase, entry] of Object.entries(source)) {
                if (Object.values(entry).includes(phrase)) {
                    return enPhrase;
                }
            }
        }

        let enPhrase = checkSource(dictionary);

        if (!enPhrase) {
            enPhrase = checkSource(slugsDict);
        }

        return enPhrase || phrase;
    }

    if (!entry) {
        entry = getEntry(phrase);
    }

    return entry?.[lang] || slugsDict[phrase]?.[lang] || phrase;
}

/**
 * Attempt to get the original phrase from the route's current English Dictionary.
 * 
 * Used to allow WP driven pages to retain ability to fetch their associated content.
 * 
 * @param { String } phrase 
 * @returns String
 */
export function getOriginalPhrase (phrase) {
    if (!router.currentRoute?.value) {
        throw new Error("Unable to get original phrase; unable to determine current route");
    }

    let route = router.currentRoute.value;

    const lang = route.meta?.lang;
    const enDictionary = route.meta?.enDictionary;

    if (!(lang && lang !== "en") || !(enDictionary && Object.values(enDictionary)?.length)) {
        return phrase;
    }

    return enDictionary[phrase] || phrase;
}

/**
 * Translate the Route to point to the appropriate page for the provided Language Sub Directory.
 * 
 * If the language does not have an associated sub directory, returns the EN / Original route.
 * 
 * @param { RouteLocation | String } route 
 * @param { String } lang 
 * @returns RouteLocation
 */
export function translateRoute (route, lang = "en", source = "en") {
    if (!langs.includes(lang)) {
        lang = "en";
    }

    if (!langs.includes(source)) {
        source = "en";
    }

    if (source === lang) {
        return route;
    }

    if (!(route && ["object", "string"].includes(typeof route))) {
        throw new Error("Invalid parameter passed in for route");
    }

    let res = typeof route === "string" ? { path: route } : { ...toRaw(route) };

    if (!(res.name || res.path)) {
        throw new Error("Destination Misconfigured");
    }

    if (!res.name && res.path) {
        // Resolve Path and convert to use a Named Route
        const resolvedRoute = router.resolve(res.path);

        if (!resolvedRoute.name) console.warn("Resolved Route does not have a name", resolvedRoute);

        res = {
            name: resolvedRoute.name,
            path: resolvedRoute.path,
            query: resolvedRoute.query,
            params: resolvedRoute.params,
            hash: resolvedRoute.hash
        }
    }

    if (res.name) {
        if (res.name === "PageNotFound") return res;

        let name = res.name;
        const parts = res.name.split("-lang_");

        if (lang !== "en") {
            if (parts.length === 1) {
                parts.push(lang);
            } else {
                parts[parts.length - 1] = lang;
            }

            name = parts.join("-lang_");
        } else {
            name = parts[0];
        }

        res.name = name;
    }

    if (res.params?.slug) {
        res.params.slug = getTranslation(res.params.slug, lang, source);
    }

    if (res.params?.pathMatch) {
        const path = res.params.pathMatch.join("/");
        const originalPath = getTranslation(path, lang, source);
        res.params.pathMatch = originalPath.split("/");
    }

    return router.resolve(res);
}

/**
 * Compare routes to verify they point to the same underlying resource
 * 
 * @param { RouteLocation } A 
 * @param { RouteLocation } B 
 * @returns Booelean
 */
export function compareRoutes (A, B) {
    if (!(A && B)) {
        return false;
    }

    // Compare Route name roots
    const aNameParts = A.name?.split("-lang_") || [];
    const bNameParts = B.name?.split("-lang_") || [];

    if (aNameParts[0] !== bNameParts[0]) {
        return false;
    }

    // TODO: consider expansion

    return true;
}

/**
 * Update current route for the provided language & adjust the language in Localize.
 * 
 * If the language does not have an enabled sub directory, defaults to EN / Original route
 * 
 * @param {String} lang 
 */
export function updateCurrentRoute (lang, source, allowScroll = false) {
    const route = router.currentRoute.value;

    const dest = translateRoute(route, lang, source || route.meta?.lang);
    dest.params.noscroll = !allowScroll;
    dest.params.pretranslated = true;

    window.Localize?.setLanguage(lang);

    if (route.name !== dest.name) {
        router.replace(dest);
    }
}

export default {
    getEntry,
    getTranslation,
    translateRoute,
    updateCurrentRoute
}