/**
 * User Composable
 * 
 * Allows use of a single shared User instance anywhere in the app, including classes or other composable files.
 * 
 * Used primarily to fix Tab/Window sync issue with UserInstance.
 * Vuex may allow classes to be shared in a global scope, but the Broadcast handler cannot broadcast messages that include functions due to Json stringify limitations.
 * JSON Stringify removes all functions from the UserInstance store property, making its JSON value the exact same as the User store property
 * 
 */

import User from '@/classes/User';

let user = null;

export function getInstance () {
    if (!user) {
        user = new User();
    }

    return user;
}

export default {
    getInstance
}