import "core-js/modules/es.number.constructor.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-38c53fa4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "flex-col"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  style: {
    "margin-bottom": "10px"
  }
};
import { toRef } from 'vue';
import { useCarouselController } from "@/composables/carouselController";
export default {
  props: {
    useExtraContent: Boolean,
    setSlide: {
      type: Function,
      default: function _default() {}
    },
    number: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    /**
     * Prefer utilization of Ancestor's injected values
     * Defaults to props if no Ancestor has provided values
     */

    var _useCarouselControlle = useCarouselController({
      defaultSetSlideNum: props.setSlide,
      defaultNumSlides: toRef(props, "number"),
      defaultActiveSlide: toRef(props, "active")
    }),
        setSlideNum = _useCarouselControlle.setSlideNum,
        numSlides = _useCarouselControlle.numSlides,
        activeSlide = _useCarouselControlle.activeSlide;

    return function (_ctx, _cache) {
      return _unref(numSlides) && _unref(numSlides) > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(["indicator-container", {
          vertical: __props.vertical
        }])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(numSlides), function (index) {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["indicator", {
            active: index - 1 === _unref(activeSlide)
          }]),
          onClick: function onClick(event) {
            event.stopPropagation();

            _unref(setSlideNum)(index - 1);
          }
        }, null, 10, _hoisted_2);
      }), 128))], 2), __props.useExtraContent ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
    };
  }
};