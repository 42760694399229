import { ref } from 'vue'

// Record of all open modals; there may be some modals that may be opened simultaneously (Auth & Password Reset)
const openModals = ref([]);

/**
 * Register given modal name to the record to prevent lesser modals from overlapping it. Will not register a null value, nor duplicates
 * 
 * Currently only prevents Football Modal from opening when another modal is open.
 * @param {string} name 
 */
export function registerModal(name) {
    if (name && !openModals.value.includes(name)) {
        openModals.value.push(name);
    }
}

/**
 * Unregister the given modal name, if it exists in the record.
 * 
 * Once record is empty, allows Football Modal, or any other lesser modals, to open.
 * @param {string} name 
 */
export function unregisterModal(name) {
    if (name && openModals.value.includes(name)) {
        openModals.value.splice(openModals.value.indexOf(name), 1);
    }
}

/**
 * Determine if there are any significant modals currently open.
 * 
 * All significant modals (Auth, NavMenuOverlay, etc) that should not be overlapped by a separate insignificant modal (Football Modal, etc),
 * should register with registerModal() upon opening.
 * @returns {boolean} indicates if there are any significant open modals
 */
export function hasOpenModals() {
    return openModals.value.length !== 0;
}