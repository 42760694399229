import { reactive, onMounted, onUnmounted, computed, ref } from 'vue';
import { throttle } from '@/helpers';
import { refDebounced } from '@vueuse/shared';
import Enum from "@/classes/Enum";

const height = ref(window.innerHeight);
const width = ref(window.innerWidth);

const windowSize = reactive ({ 
    height : computed({
        get () {
            return height.value;
        },
        set (val) {
            height.value = val;
        }
    }),
    width : computed({
        get () {
            return width.value;
        },
        set (val) {
            width.value = val;
        }
    }),
    // ClientHeight remains consistent for max UI Size for enabled rows (bookmarks, tabs, tools, etc.); innerHeight grows & shrinks based on minified Browser UI & reload drag state, can be bigger or smaller than ClientHeight
    vh : Math.max(window.innerHeight * 0.01, document.documentElement.clientHeight * 0.01)
});

function onResize() {
    windowSize.height = window.innerHeight;
    windowSize.width = window.innerWidth;
    windowSize.vh = Math.max(window.innerHeight * 0.01, document.documentElement.clientHeight * 0.01);

    document.documentElement.style.setProperty('--vh', `${windowSize.vh}px`);
}

export function getSize() {
    return windowSize;
}

export const breakpoints = reactive({
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1920
});

export const BPEnum = new Enum([
    "xs",
    "sm",
    "md",
    "lg",
    "xl"
]);

export const currentBP = computed(() => {
    if( breakpoints.xl <= windowSize.width ) {
        return "xl";
    } else if ( breakpoints.lg <= windowSize.width ) {
        return "lg";
    } else if ( breakpoints.md <= windowSize.width ) {
        return "md";
    } else if ( breakpoints.sm <= windowSize.width ) {
        return "sm";
    }

    return 'xs';
});

/**
 * return a more accurate 1vh pixel unit for the actual viewport; Mainly for Safari, but should work on other mobile devices
 * 
 * TODO: consider dynamic inclusion depending on User Agent
 */
export const getViewportHeightUnit = computed(() => {
    return windowSize.vh;
});

let strategy = null;
const registeredElemsResized = {};

/**
 * Standardize setup for watch functions in both Strategies.
 * Verify inputs, check if already registered, and sets up data if not registered
 * 
 * @param { HTMLElement } elem 
 * @param { Object } attrs 
 * @returns 
 */
const setupWatch = (elem, attrs) => {
    if (!elem || typeof elem.getAttribute('data-key') === "undefined") {
        throw new Error("Missing element or data-key property");
    }

    if (!(Array.isArray(attrs) && attrs.length)) {
        throw new Error("Invalid attrs parameter");
    }

    const recData = registeredElemsResized[elem.getAttribute('data-key')];

    if (recData && recData.attrs.length === attrs.length && recData.attrs.every((val) => attrs.includes(val))) {
        return 0; // Item is already being tracked with current attributes
    }

    registeredElemsResized[elem.getAttribute('data-key')] = {};
    registeredElemsResized[elem.getAttribute('data-key')].attrs = attrs;

    return 1;
}

/**
 * Update the recorded size for the element.
 * Creates an entry if the element was not registered before. Uses a "data-key" attribute on the HTMLElement to record data.
 * 
 * Ref value updates are debounced to prevent multiple page redraws in a short timespan.
 * @param { HTMLElement } elem 
 */
const updateSize = (elem) => {
    const key = elem.getAttribute('data-key');

    if (!registeredElemsResized[key].data?.value) {
        registeredElemsResized[key].data = ref({});
        registeredElemsResized[key].res = refDebounced(registeredElemsResized[key].data, 17);
    }

    const attrs = registeredElemsResized[key].attrs;
    const obj = {};

    for(let attr of attrs) {
        switch (attr) {
            case "height":
                obj[attr] = elem["clientHeight"];
                break;
            case "width":
                obj[attr] = elem["clientWidth"];
                break;
            default:
                obj[attr] = elem[attr];
        }
    }

    registeredElemsResized[key].data.value = obj;
}

const ResizeObserverStrategy = () => {
    /**
     * Resize function to be used by the ResizeObserver.
     * 
     * As ResizeObserver batches element updates together, throttle/debounce does not appear to be as important.
     * Throttling/Debouncing this function causes inaccurate updates as individual triggers may be skipped & values are not recorded.
     * 
     * To still save on performance, ref updates in updateSize are throttled individually.
     * @param {ResizeObserverEntry[]} entries 
     */
    const resizeFunc = (entries) => {
        for (const entry of entries) {
            updateSize(entry.target);
        }
    }

    const observer = new ResizeObserver(resizeFunc);

    /**
     * Register HTMLElement for observation
     * 
     * Rejects if the HTMLElement does not have a "data-key" attribute
     * @param { HTMLElement } elem
     * @param { String[] } attrs List of DOM element dimension attributes to track; shorthand "width" and "height" for "clientWidth" and "clientHeight"
     * @returns computed ref for element's dimensions
     */
    const watch = (elem, attrs = ["height", "width", "scrollHeight", "scrollWidth"]) => {
        if (setupWatch(elem, attrs)) {
            observer.observe(elem);
            updateSize(elem);
        }

        return computed(() => registeredElemsResized[elem.getAttribute('data-key')].res?.value);
    }

    /**
     * Unregister HTMLElement from observation
     * @param {HTMLElement} elem 
     */
    const unwatch = (elem) => {
        observer.unobserve(elem);
    }

    return { watch, unwatch };
}

const EventListenerStrategy = () => {
    // Record the elements being watched; allows for a single resize eventListener in order to minimize number of function calls
    const entries = [];

    /**
     * Event listener function; attempts to mimic the behavior of ResizeObserver
     */
    const updateAllElems = throttle(() => {
        for (let entry in entries) {
            updateSize(entries[entry]);
        }
    }, 40);

    /**
     * Register HTMLElement for observation
     * 
     * Rejects if the HTMLElement does not have a "data-key" attribute
     * @param { HTMLElement } elem
     * @param { String[] } attrs List of DOM element dimension attributes to track; shorthand "width" and "height" for "clientWidth" and "clientHeight"
     * @returns computed ref for element's dimensions
     */
    const watch = (elem, attrs = ["height", "width", "scrollHeight", "scrollWidth"]) => {
        if (setupWatch(elem, attrs)) {
            // Add eventListener if first entry in watch list
            if (!entries.length) {
                window.addEventListener("resize", updateAllElems);
            }

            entries.push(elem);
            updateSize(elem);
        }

        return computed(() => registeredElemsResized[elem.getAttribute('data-key')].res?.value);
    }

    /**
     * Unregister HTMLElement from observation
     * @param {HTMLElement} elem 
     */
    const unwatch = (elem) => {
        if (entries.includes(elem)) {
            entries.splice(entries.indexOf(elem), 1);

            // Cleanup eventListener if there are no more elements being watched
            if (!entries.length) {
                window.removeEventListener("resize", updateAllElems);
            }
        }
    }

    return { watch, unwatch }
}

if (typeof ResizeObserver === "function") {
    strategy = ResizeObserverStrategy();
} else {
    strategy = EventListenerStrategy();
}

export const registerResize = strategy.watch;
export const removeResize = strategy.unwatch;

/**
 * 
 */
export const initializeWW = () => {
    onMounted(() => window.addEventListener( 'resize' , onResize ));
    onUnmounted(() => window.removeEventListener( 'resize' , onResize ));

    document.documentElement.style.setProperty('--vh', `${windowSize.vh}px`);
}