export default {
    state: {
        subscription: null,
        productChangeRequests: {},
        productChangeError: null
    },
    mutations: {
        setSubscription (state, sub) {
            state.subscription = { ...sub };
        },
        setProductChangeRequest (state, request) {
            state.productChangeRequests[request.fs_sub_id] = request;
        },
        unsetProductChangeRequest (state, fsSubscriptionId) {
            delete state.productChangeRequests[fsSubscriptionId];
        },
        resetProductChangeRequests (state) {
            state.productChangeRequests = {};
        },
        setProductChangeError (state, error = null) {
            state.productChangeError = error;
        }
    },
    getters: {
        getSubscription (state) {
            return state.subscription;
        },
        getProductChangeRequests (state) {
            return state.productChangeRequests;
        },
        getProductChangeError (state) {
            return state.productChangeError;
        }
    }
};