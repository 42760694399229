import { ElMessage } from "element-plus";
import HttpClient from "@/classes/HttpClient";

let success = null;
let error = null;

export function handleEmailFieldFocusEvent() {
    if (success) {
        success.close();
    }
    if (error) {
        error.close();
    }
}

export async function handleSubmit(form, state, { loadingKey = 'loading', submittedKey = "submitted" }, returnRes = false) {
    success?.close();
    error?.close();

    let res = 0;

    const resetForm = () => {
        form.email = '';
        form.fax = false;
    };

    const showMessage = (message, type = 'success') => {
        return ElMessage({
            showClose: true,
            message,
            type,
            duration: 10000
        });
    };

    if (form.fax) {
        return returnRes ? res : null;
    }

    let resp = null;

    try {
        state[loadingKey] = true;
        resp = await (new HttpClient({path: '/laravel/rest/emailsignup', verify: false })).post({ email: form.email });

        if (resp.status === 200) {
            res = resp.status;
            resetForm();
            return resp.status;
        } else if (resp.status === 400) {
            if (resp.data === "Email address already registered") {
                res = resp.status;
                resetForm();
                return resp.status;
            } else {
                error = showMessage(resp.data, 'error');
            }
        } else {
            throw new Error(resp.status);
        }

        resetForm();
    } catch (e) {
        console.error(e);
        error = showMessage("Unable to process request at this time. Please try again later.", 'error')
    } finally {
        let successMsg = null;

        if (res === 200) {
            state[submittedKey] = true;
            successMsg = "Please check your email";
        } else if (res === 400) {
            successMsg = resp?.data;
        }

        if (!returnRes && successMsg) {
            success = showMessage(successMsg);
        }

        state[loadingKey] = false;
    }
}

export default {
    handleSubmit,
    handleEmailFieldFocusEvent
}