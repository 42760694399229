import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["viewBox"];
export default {
  props: {
    viewBox: String
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: __props.viewBox,
        class: "iconBase"
      }, [_renderSlot(_ctx.$slots, "default")], 8, _hoisted_1);
    };
  }
};