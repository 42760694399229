
class Entry {
    constructor (name, id) {
        this.symbol = Symbol(name);
        this.id = id;
    }

    valueOf () {
        return this.id;
    }

    toString () {
        return this.symbol.description;
    }

    getName () {
        return this.symbol.description;
    }
}

export default class Enum {

    /**
     * Construct the Enum with the provided list of properties.
     * Creates Properties and its associated Symbols.
     * Property value is a Symbol instance to prevent differing Enum Instances from falsely matching to the same property
     * 
     * Note: This object appears to be unserializable in JSON due to the Symbol type property values.
     * 
     * @param {String[]} items Array of Strings used to define the enum properties; Also utilized to define the Symbols for each property
     * @returns 
     */
    constructor (items) {
        if (!(items && Array.isArray(items) && items.length && items.every(item => item && typeof item === "string"))) {
            throw new Error("Parameter must be a non-empty array of strings");
        }

        items.forEach((item, index) => {
            this[item] = new Entry (item, index);
        });

        return Object.freeze(this); // Instance should be immutable
    }

    /**
     * Retrieve the numerical index of the passed in Symbol
     * 
     * @param {Symbol} symbol Symbol value to search for within the Enum
     * @returns Index Position of the Symbol; -1 if Symbol cannot be found
     */
    indexOf (symbol) {
        return Object.values(this).indexOf(symbol);
    }

    getByIndex (index) {
        return Object.values(this)[index];
    }
}