import { createRouter, createWebHistory } from 'vue-router';
import { throttleCachebustingReloads, setCanonicalUrl, setHrefLangTags, resetSeo, updateConvert, checkCurrentIp } from '@/helpers';
import { translateRoute } from "@/helpers/language";
import store from '@/store';
import routes from './routes';

let CSSObserver = null;
let isInitialLoad = true;

const setBgVar = (entry) => {
  if (entry.dataset.lazyBg) {
    entry.style.setProperty("--data-image", `url(${entry.dataset.lazyBg})`);
  }

  if (entry.dataset.lazyBgTablet) {
    entry.style.setProperty("--data-tablet-image", `url(${entry.dataset.lazyBgTablet})`);
  }

  if (entry.dataset.lazyBgMobile) {
    entry.style.setProperty("--data-mobile-image", `url(${entry.dataset.lazyBgMobile})`);
  }
}

/**
 * Lazy load Css Background images; to use, attach image under "data-lazy-bg" tag on the element & use the css variable "--data-image" on the element or descendent element styling.
 * 
 * Function captures a snapshot of elements loaded with the "data-lazy-bg" tag & adds them to the IntersectionCSSObserver, if supported.
 * If IntersectionObserver is not supported, function defaults to eager loading behavior.
 * 
 * @returns {IntersectionObserver|Null} Observer object configured for Lazy BG images if IntersectionObserver is supported in the browser, null if it is not. Returning IntersectionObserver to allow us to add new elements after initial call.
 */
const initCSSLazyLoader = () => {
  const desktopBGs = Array.from(document.querySelectorAll('[data-lazy-bg]')).filter(BG => {
    // Filter out Elements that have already been processed by CSSObserver
    return typeof BG.getAttribute("style")?.indexOf("--data-image") === "undefined"
  });

  const tabletBGs = Array.from(document.querySelectorAll('[data-lazy-bg-tablet]')).filter(BG => {
    // Filter out Elements that have already been processed by CSSObserver
    return typeof BG.getAttribute("style")?.indexOf("--data-tablet-image") === "undefined"
  });

  const mobileBGs = Array.from(document.querySelectorAll('[data-lazy-bg-mobile]')).filter(BG => {
    // Filter out Elements that have already been processed by CSSObserver
    return typeof BG.getAttribute("style")?.indexOf("--data-mobile-image") === "undefined"
  });

  const BGs = desktopBGs.concat(tabletBGs, mobileBGs).filter((elem, index, elems) => {
    return elems.indexOf(elem) === index;
  });

  if (!BGs.length) {
    return;
  }

  const loadImg = (entry) => {
    if (entry.isIntersecting) {
      setBgVar(entry.target);
      CSSObserver.unobserve(entry.target);
    }
  }

  if (typeof window.IntersectionObserver === "function") {

    if (!CSSObserver) {
      CSSObserver = new IntersectionObserver((entries) => {
        entries.forEach(loadImg);
      });
    }

    CSSObserver.disconnect(); // Ensure clean IntersectionObserver. Useful for re-inits

    BGs.forEach((item) => {
      CSSObserver.observe(item);
    })
  } else {
    BGs.forEach(setBgVar);
  }
}

/**
 * Register provided entry for CSS Lazy Loading.
 * 
 * If browser does not support IntersectionObserver or IntersectionObserver has not been instantiated, immediately loads the BG images
 * 
 * @param {HTMLElement} entry 
 */
const registerCSSLazyObserve = (entry) => {
  if (typeof window.IntersectionObserver === "function" && CSSObserver) {
    CSSObserver.observe(entry);
  } else {
    setBgVar(entry);
  }
}

/**
 * Determines the scrolling behavior for product routes.
 * @param {object} to 
 * @param {object} from 
 * @returns 
 */
const shouldPreventScrollOnProductRoutes = (to, from) => {
  const fromLastMatch = from?.matched[from.matched.length - 1];
  const toLastMatch = to?.matched[to.matched.length - 1];

  if (!fromLastMatch || !toLastMatch) {
    return false;
  }

  const { path: fromPath } = fromLastMatch;
  const { path: toPath } = toLastMatch;

  if (fromPath.endsWith(':slug?') && toPath.endsWith(':subnav_slug?')) {
    return true;
  }

  if (fromPath.endsWith(':subnav_slug?') && toPath.endsWith(':subnav_slug?')) {
    return true;
  }

  return false;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.params?.noscroll || shouldPreventScrollOnProductRoutes(to, from)) {
      return null;
    }

    let target = {
      top : 0,
      left : 0,
      behavior: 'smooth'
    };

    if (to.hash) {
      target = {
        el: to.hash,
        behavior: 'smooth',
      }

      if (to.path !== from.path) {
        /** 
         * Delay the scroll by 1s to give some time for page content to load;
         * Not optimal, but a quick patch for the scroll handler.
         * 
         * Ideally, wait until the page's content request finishes before performing the scroll,
         * but will require quite a bit of work to adjust each network request.
         * Best bet would be to initiate the scroll via scrollTo() or a similar function after the network request is complete
         * 
         * Delay is ignored if no page transition occurs
         */
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(target)
          }, 1000)
        });
      }
    }

    return target;
  }
});

/**
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 */

//  ---> Auth NavGuard moved to /Components/AuthModal.vue

// Allow CSS Lazy Loader (re-) Init if route does not require CMS data or if a delay is not required.
const allowCSSLLInit = (route) => {
  return !(route.meta?.fromCms || route.meta?.delayLazyLoader);
}

let request = null;

const checkUserState = () => {
  const user = store.getters.getUserInstance;
  
  if (!user?.id) {
    return;
  }

  if (request) {
    return;
  }

  // Track request to prevent multiple overlapping requests on current Tab/Window
  request = user.refresh().catch(e => {
    if (e.message !== "401") {
      console.error(e);
    }
  }).finally(() => request = null);
}

router.beforeEach((to, from, next) => {
  checkUserState();

  // Adjust the destination should the destination be missing the language code
  if (to.name !== "PageNotFound" && from.meta?.lang && !(to.meta?.lang || to.params?.pretranslated)) {
    next(translateRoute(to, from.meta.lang));
  } else {
    next();
  }
});

router.afterEach(to => {
  if (isInitialLoad) {
    isInitialLoad = false;
  } else { // any code that should __not__ run on initial pageload should go here
    if (!to.meta.suppressIpCheck) {
      checkCurrentIp();
    }
  }

  if (!to.meta.requiresAuth) {
    updateConvert();
  }

  if (to.name === 'SubscriptionDetail') {
    document.title = 'Subscriptions';
  } else if (!to.meta?.fromCms) {
    resetSeo();
    /* Put spaces between capital letters and trim all extra white space */
    const name = to.name.split("-lang_")[0];
    document.title = name.replace(/([A-Z])/g, ' $1').trim() + ' | Antares Tech';
  }

  if (Object.values(to.query).length && store.getters.getEventBus['queryStringTrigger']) {
    store.getters.getEventBus['queryStringTrigger'](to);
  }

  // Clean out CSSObserver to prevent updating elements no longer in the page; Also prevents global components (Footer) from loading their Image due to page height changes mid transition
  if (CSSObserver) {
    CSSObserver.disconnect();
  }

  // re-init CSS Lazy Loader if allowed
  if (allowCSSLLInit(to)) {
    initCSSLazyLoader();
  }

  setCanonicalUrl(to.path);
  setHrefLangTags();
});

// Setup for initial site load
router.isReady().then(() => {
  if (allowCSSLLInit(router.currentRoute.value)) {
    initCSSLazyLoader();
  }
});

router.initCSSLazyLoader = initCSSLazyLoader;
router.registerCSSLazyObserve = registerCSSLazyObserve;

router.onError(e => {
  if (/ChunkLoadError:.*failed./i.test(e.message) && navigator.onLine) {
    throttleCachebustingReloads(true);
  } else if (/Loading.*chunk.*failed./i.test(e.message) && navigator.onLine) {
    throttleCachebustingReloads(true);
  }
});

export default router;
