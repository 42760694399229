// import HttpClient from './HttpClient';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { handleAsyncSeoMetaTags, checkCacheKey } from '@/helpers';
import { watch, computed } from "vue";
import { compareRoutes } from '@/helpers/language';

export default class WpHttpClient {

    constructor ({ namespace = null, path, query = null, global = false, config = {} }) {
        let baseUrl = `${process.env.VUE_APP_WP_BASEURL}/wp-json`;

        if (namespace) {
            baseUrl += namespace;
        } else {
            baseUrl += '/wp/v2';
        }

        this.url = baseUrl + path;
        this.query = query;
        this.config = config;

        axios.defaults.verify = false;

        if (this.config.signal) {
            // Signal set up externally; no need to set up Abort Controller
            return;
        }

        if (typeof AbortController === "function") {
            this.controller = new AbortController();
        }

        if (!this.controller) {
            return;
        }

        if (!(this.controller.signal && this.controller.abort && typeof this.controller.abort === "function")) {
            this.controller = null; // Clear out Stub & return
            return;
        }
        
        this.config.signal = this.controller?.signal;

        // No need to instantiate watchers on global requests
        // if abort is needed, it should be manually called on request object
        if (global) {
            return;
        }

        const activeWatchers = [];

        const stopWatching = () => {
            activeWatchers.forEach((abortWatch) => abortWatch());
        }

        const route = computed(() => router.currentRoute.value);
        const abortRequests = computed(() => store.getters.getAbortRequestsFlag);

        activeWatchers.push(watch(route, (to, from) => {
            if (!compareRoutes(to, from)) {
                this.abort();
                stopWatching();
            }
        }));

        activeWatchers.push(watch(abortRequests, () => {
            if (abortRequests.value) {
                this.abort();
                stopWatching();
            }
        }));
    }

    async get () {
        const resp = await axios.get(this.buildUrl(), this.config);

        if (store.getters.getLastWpRoute !== router.currentRoute.value.path) {
            checkCacheKey(resp);
        }
        
        store.commit('setLastWpRoute', router.currentRoute.value.path);
        handleAsyncSeoMetaTags(resp);

        return this.checkStatus(resp);
    }

    async post (payload) {
        return this.checkStatus(await axios.post(this.buildUrl(), payload, this.config));
    }

    async put (payload) {
        return this.checkStatus(await axios.put(this.buildUrl(), payload, this.config));
    }

    async patch (payload) {
        return this.checkStatus(await axios.patch(this.buildUrl(), payload, this.config));
    }

    async delete (payload) {
        return await axios.delete(this.buildUrl(), payload, this.config);
    }

    /**
     * Builds a URL, including adding query string parameters. 
     * @returns {string}
     */
    buildUrl () {
        let url = new URL(this.url);

        if (this.query && Object.keys(this.query).length) {
            for (let q in this.query) {
                const val = this.query[q];

                if (typeof val !== 'boolean' && typeof val !== 'number' && !val) {
                    continue;
                }

                url.searchParams.set(q, `${val}`);
            }
        }

        return url.href;
    }

    /**
     * Checks the status of a response.
     * @param {object} resp 
     * @returns {object}
     */
    checkStatus (resp) {
        if (!resp) {
            throw new Error ("Unknown Error");
        }

        if (!resp.status) {
            throw new Error (resp.message);
        }

        if (!(resp.status === 200)) {
            throw new Error(resp.status);
        }

        return resp;
    }

    /**
     * Aborts currently running request.
     * 
     * Only available if browser supports AbortController
     */
    abort () {
        try {
            this.controller?.abort();
        } catch (e) {
            console.warn(e);
        }
    }

    /**
     * Fetches content from the WP REST API.
     * @param {string} slug
     * @return {string}
     */
    static async fetchContent (slug) {
        let url = `${process.env.VUE_APP_WP_BASEURL}/pages?slug=${slug}`;
        const resp = await axios.get(url);
        
        if (!(resp && resp.status)) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        return resp.data[0].content.rendered;
    }
}