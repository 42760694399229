import "core-js/modules/es.array.includes.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
export default {
  props: {
    height: {
      type: String,
      required: false,
      default: 'none',
      validator: function validator(v) {
        return ['full', 'three-quarters', 'half', 'quarter', 'none'].includes(v.toLowerCase());
      }
    },
    align: {
      type: String,
      required: false,
      default: 'top',
      validator: function validator(v) {
        return ['top', 'middle', 'bottom'].includes(v.toLowerCase());
      }
    },
    justify: {
      type: String,
      required: false,
      default: 'start',
      validator: function validator(v) {
        return ['start', 'end', 'center', 'space-around', 'space-between', 'space-evenly'].includes(v.toLowerCase());
      }
    },
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
      validator: function validator(v) {
        return ['horizontal', 'vertical'].includes(v.toLowerCase());
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "4b167aa2": _unref(justifyType),
        "761b9ad2": _unref(alignType)
      };
    }); // Computed properties


    var heightClass = computed(function () {
      switch (props.height.toLowerCase()) {
        case 'full':
          return 'viewport-min-height-minus-navbar';

        case 'three-quarters':
          return 'viewport-min-height-minus-navbar__three-quarters';

        case 'half':
          return 'viewport-min-height-minus-navbar__half';

        case 'quarter':
          return 'viewport-min-height-minus-navbar__quarter';

        default:
          return null;
      }
    });
    var justifyType = computed(function () {
      switch (props.justify.toLowerCase()) {
        case 'start':
          return 'flex-start';

        case 'end':
          return 'flex-end';

        default:
          return props.justify;
      }
    });
    var alignType = computed(function () {
      switch (props.align.toLowerCase()) {
        case 'top':
          return 'flex-start';

        case 'middle':
          return 'center';

        case 'bottom':
          return 'flex-end';
      }

      return null;
    });
    return function (_ctx, _cache) {
      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_component_el_container, {
        class: _normalizeClass(["default-banner", _unref(heightClass)]),
        direction: __props.direction.toLowerCase()
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["direction", "class"]);
    };
  }
};