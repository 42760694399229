import "core-js/modules/es.array.includes.js";
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
export default {
  props: {
    type: {
      type: String,
      default: '',
      validator: function validator(v) {
        return ["", "success", "info", "warning", "danger"].includes(v.toLowerCase());
      }
    },
    size: {
      type: String,
      default: "default",
      validator: function validator(v) {
        return ["small", "default", "large"].includes(v.toLowerCase());
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var cssColorPalette = computed(function () {
      return {
        dark_2: "var(--el-color-".concat(props.type.toLowerCase(), "-dark-2)"),
        default: "var(--el-color-".concat(props.type.toLowerCase(), ")"),
        light_3: "var(--el-color-".concat(props.type.toLowerCase(), "-light-3)"),
        light_5: "var(--el-color-".concat(props.type.toLowerCase(), "-light-5)"),
        light_7: "var(--el-color-".concat(props.type.toLowerCase(), "-light-7)"),
        light_8: "var(--el-color-".concat(props.type.toLowerCase(), "-light-8)"),
        light_9: "var(--el-color-".concat(props.type.toLowerCase(), "-light-9)")
      };
    });
    var fontSize = computed(function () {
      var value = "14px";

      switch (props.size) {
        case "small":
          value = "10px";
          break;

        case "large":
          value = "18px";
          break;

        default:
          value = "14px";
      }

      return value;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "tag",
        style: _normalizeStyle({
          'font-size': _unref(fontSize),
          'border-color': _unref(cssColorPalette).light_8,
          'background-color': _unref(cssColorPalette).light_9,
          'color': _unref(cssColorPalette).default
        })
      }, [_renderSlot(_ctx.$slots, "default")], 4);
    };
  }
};