import { computed, inject, ref } from "vue";

/**
 * Initialize controller functions for instance of Carousel Indicators.
 * Attempts to inject controller methods/values from ancestor if available;
 * otherwise defaults to provided values
 * 
 * @param { Function } defaultSetSlideNum Function to call when user clicks on an indicator, should take an index number; default: (index) => {}
 * @param { Ref<Number> } defaultNumSlides Number of slides present in the Carousel, used to define number of indicators; default: 0
 * @param { Ref<Number> } defaultActiveSlide Current active index of the Carousel, used to allow highlighting current active indicator; default -1
 * @returns 
 */
export const useCarouselController = ({defaultSetSlideNum = () => {}, defaultNumSlides = ref(0), defaultActiveSlide = ref(-1)}) => {
    const setSlideNum = inject("setSlideNum", computed(() => defaultSetSlideNum));
    const numSlides = inject("numSlides", computed(() => defaultNumSlides.value));
    const activeSlide = inject("activeSlide", computed(() => defaultActiveSlide.value));

    return { setSlideNum, numSlides, activeSlide };
}