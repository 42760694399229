import { ElMessage } from "element-plus";

let error = null;

/**
 * Close & clear current Auth error notification
 */
export function closeErrNotif() {
    if (error && typeof error.close === "function") {
        error.close();
        error = null;
    }
}

/**
 * Process error message from Auth request.
 * 
 * @param { Number } e HTTP status code
 * @returns Boolean - Determines if a full reset is allowed
 */
export function handleAuthError (e) {
    let message = 'An unknown error has occurred. Please try again later.';
    let fullReset = true;

    switch (e) {
        case '400':
        case '401':
        case '403':
            message = 'Invalid email address or password';
            fullReset = false;
            break;
        case '424':
            message = 'You have attempted to sign in with a legacy account. Please check your email for further instructions on reactivating your account.';
            break;
        case '409':
            message = 'An account with this email already exists. Please log in.';
            break;
        case '500':
            message = 'Could not process your request at this time. Please try again.';
            fullReset = false;
            break;
        case '503':
            message = 'Service temporarily unavailable. Please try again later.';
            fullReset = false;
            break;
        default:
            console.warn(e);
    }

    error = ElMessage ({
        onClick: () => error.close(),
        message,
        type: 'error',
        customClass: 'login-message',
        duration: 20000
    });

    return fullReset;
}