import _objectSpread from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { h } from "vue";
import CarouselContainer from "@/components/CarouselBanner/CarouselContainer";
export default {
  props: CarouselContainer.props,
  setup: function setup(__props) {
    var props = __props;
    var forwardedComp = h(CarouselContainer, _objectSpread({}, props));
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(forwardedComp)), null, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      });
    };
  }
};