import "core-js/modules/es.array.includes.js";
import { unref as _unref, renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { BPEnum, currentBP, getSize } from "@/composables/windowWatcher.js";
import { throttle } from '@/helpers';
export default {
  props: {
    maxScreenSize: {
      type: String,
      required: false,
      default: "xl",
      validator: function validator(val) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(val);
      }
    },
    position: {
      type: String,
      required: true,
      default: "top",
      validator: function validator(val) {
        return ['top', 'bottom'].includes(val);
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var windowSize = getSize();
    var trigger = ref(null);
    var current = ref(0);
    var footer = ref(null);
    /**
     * 
     * Computed Properties
     * 
     */

    var allowedInBp = computed(function () {
      var currBp = BPEnum[currentBP.value];
      var maxBp = BPEnum[props.maxScreenSize];
      return currBp <= maxBp;
    });
    /**
     * Scroll threshold before sticky box should show.
     */

    var threshold = computed(function () {
      var docTop = document.documentElement.scrollTop;
      var offset = props.position === "bottom" ? windowSize.height : 70;

      if (!trigger.value) {
        return docTop - offset;
      }

      return Math.floor(trigger.value.getBoundingClientRect().top + docTop - offset);
    });
    /**
     * Footer Threshold
     * 
     * Scrolling past this hides the sticky box.
     * 
     * If no footer is found; defaults to INF to allow continued functionality
     */

    var footerThreshold = computed(function () {
      var docTop = document.documentElement.scrollTop;
      var offset = props.position === "bottom" ? windowSize.height : 70;

      if (!footer.value) {
        return Infinity;
      }

      return Math.floor(footer.value.getBoundingClientRect().top + docTop - offset);
    });
    /**
     * Determine if the sticky box should be visible.
     */

    var visible = computed(function () {
      if (!(trigger.value && allowedInBp.value)) {
        return false;
      }

      return threshold.value <= current.value && current.value <= footerThreshold.value;
    });
    /**
     * 
     * Methods
     * 
     */

    var initialize = function initialize() {
      footer.value = document.getElementById('footer');
      window.addEventListener('scroll', handleScroll, {
        passive: true
      });
    };

    var uninitialize = function uninitialize() {
      window.removeEventListener('scroll', handleScroll);
    };
    /**
     * Tracks the current scrollTop value.
     * 
     * Pre-throttled to help with removing Event Listener later on unmount
     * 
     * @param {object} e
     */


    var handleScroll = throttle(function (e) {
      current.value = document.documentElement.scrollTop;
    }, 80);
    /**
     * 
     * Lifecycle hooks
     * 
     */

    onMounted(function () {
      return initialize();
    });
    onBeforeUnmount(function () {
      return uninitialize();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "trigger",
        ref: trigger
      }, [_createVNode(_Transition, {
        name: "box-fade"
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["sticky-bar", __props.position])
          }, [_renderSlot(_ctx.$slots, "default")], 2), [[_vShow, _unref(visible)]])];
        }),
        _: 3
      })], 512);
    };
  }
};