export default {
    state: {
        content: {},
        abortRequests: false
    },
    actions: {
    },
    mutations: {
        setContent (state, payload) {
            state.content[payload.name] = payload.content;
        },
        setAbortRequestsFlag (state) {
            state.abortRequests = true;
            setTimeout(() => state.abortRequests = false);
        }
    },
    getters: {
        getContent: (state) => (payload) => {
            if (state.content[payload]) {
                return state.content[payload];
            }

            return null;
        },
        getAbortRequestsFlag (state) {
            return state.abortRequests;
        }
    }
};