import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import user from './user';
import cart from './cart';
import fs from './fs';
import ui from './ui';
import subscription from './subscription';
import content from './content';

import vuexTabSync from './plugins/vuexTabSync';
// Left here in case we need for debugging again later
// import localStorageWatchdog from './plugins/localStorageWatchdog';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

// Documentation on Plugins: https://vuex.vuejs.org/guide/plugins.html

export default createStore({
  modules: {
    user,
    cart,
    fs,
    ui,
    subscription,
    content
  },
  plugins: [
    vuexLocal.plugin,
    // localStorageWatchdog,
    vuexTabSync
  ]
});
