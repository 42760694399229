export default {
    state: {
        cart: null,
        notifs: [],
        dialog: {
            visible: false,
            type: "info",
            data: null
        },
        sendUserToCheckout: false
    },
    mutations: {
        setCart (state, cart) {
            const tempCart = { ...cart };
            delete tempCart.fsData; // Don't want to store this; duplicate entry
            state.cart = { ...tempCart };
        },
        setCartNotif (state, payload) {
            payload.id = Math.random().toString(36).replace(/[^a-z0-9]+/g, '');
            state.notifs.unshift(payload);
        },
        setCartDialog (state, payload) {
            state.dialog = { visible: false, type: "info", data: null, ...payload };
        },
        removeLastCartNotif (state) {
            state.notifs.pop();
        },
        unsetCartNotif (state, id) {
            state.notifs = state.notifs.filter(notif => notif.id !== id);
        },
        unsetAllCartNotifs (state) {
            state.notifs = [];
        },
        unsetCartDialog (state) {
            state.dialog = {
                visible: false,
                type: "info",
                data: null
            };
        },
        setSendUserToCheckout (state) {
            state.sendUserToCheckout = true;
            setTimeout(() => (state.sendUserToCheckout = false));
        }
    },
    getters: {
        getCart (state) {
            return state.cart;
        },
        getCartNotifs (state) {
            return state.notifs;
        },
        getCartDialog (state) {
            return state.dialog
        },
        getSendUserToCheckout (state) {
            return state.sendUserToCheckout;
        }
    }
};