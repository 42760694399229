import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["src", "loading", "alt"];
var _hoisted_2 = {
  class: "fill-container image-slot flex-centered"
};
var _hoisted_3 = {
  class: "fill-container image-slot flex-centered"
};
import { PictureFilled } from '@element-plus/icons-vue';
export default {
  props: {
    styleClass: {
      type: String,
      default: ''
    },
    loading: {
      type: String,
      default: 'eager'
    },
    lazy: {
      type: Boolean,
      default: true
    },
    fit: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    useNativeImage: Boolean
  },
  setup: function setup(__props) {
    var props = __props;

    var getImgAlt = function getImgAlt() {
      var parts = props.src.split('/');
      var fileName = parts[parts.length - 1].split('.')[0];
      return decodeURIComponent(fileName).replace(/-|_/g, ' ');
    };

    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_image = _resolveComponent("el-image");

      var _directive_loading = _resolveDirective("loading");

      return __props.useNativeImage ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: _normalizeClass(["fill-container", __props.styleClass]),
        src: __props.src,
        loading: __props.loading,
        alt: __props.alt ? __props.alt : getImgAlt(),
        style: _normalizeStyle({
          'object-fit': __props.fit
        })
      }, null, 14, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_image, {
        key: 1,
        class: _normalizeClass(__props.styleClass),
        loading: __props.loading,
        lazy: __props.lazy,
        fit: __props.fit,
        src: __props.src,
        alt: __props.alt ? __props.alt : getImgAlt()
      }, {
        placeholder: _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [[_directive_loading, true]])];
        }),
        error: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, null, {
            default: _withCtx(function () {
              return [_createVNode(_unref(PictureFilled))];
            }),
            _: 1
          })])];
        }),
        _: 1
      }, 8, ["class", "loading", "lazy", "fit", "src", "alt"]));
    };
  }
};