import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a7ff474a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  style: {
    "padding-left": "10px",
    "padding-right": "10px"
  }
};
var _hoisted_2 = {
  class: "dots"
};
var _hoisted_3 = ["onClick"];
import { reactive, computed, ref, onMounted, onUnmounted } from 'vue';
import WpTestimonial from '../WpComponents/WpTestimonial.vue';
export default {
  props: {
    testimonials: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var carousel = ref(null);
    var state = reactive({
      slides: props.testimonials,
      currentIndex: 0,
      startX: 0,
      currentTranslate: 0,
      isDragging: false
    });
    /**
     * Get the current width of the carousel
     */

    var getCurrentWidth = function getCurrentWidth() {
      var _carousel$value;

      return ((_carousel$value = carousel.value) === null || _carousel$value === void 0 ? void 0 : _carousel$value.clientWidth) || 0;
    };
    /**
     * Set slide X translate value when horizontally dragging or scrolling
     */


    var slideStyle = computed(function () {
      return {
        transform: "translateX(".concat(state.currentTranslate, "px)"),
        transition: state.isDragging ? 'none' : 'transform 0.3s ease'
      };
    });
    /**
     * Go to the slide at the given index
     * @param index 
     */

    var goToSlide = function goToSlide(index) {
      state.currentIndex = index;
      state.currentTranslate = -index * getCurrentWidth();
    };

    var onTouchStart = function onTouchStart(event) {
      state.startX = event.touches[0].clientX;
      state.isDragging = true;
    };
    /**
     * Handle touch move event
     * @param event 
     */


    var onTouchMove = function onTouchMove(event) {
      if (!state.isDragging) return;
      var moveX = event.touches[0].clientX - state.startX;
      state.currentTranslate = -state.currentIndex * getCurrentWidth() + moveX;
    };
    /**
     * Handle touch end event
     * @param event 
     */


    var onTouchEnd = function onTouchEnd(event) {
      state.isDragging = false;
      var threshold = getCurrentWidth() / 4; // Threshold for swipe

      var moveX = event.changedTouches[0].clientX - state.startX;

      if (moveX < -threshold) {
        nextSlide();
      } else if (moveX > threshold) {
        prevSlide();
      } else {
        // Reset to the current slide
        state.currentTranslate = -state.currentIndex * getCurrentWidth();
      }
    };
    /**
     * Move to the next slide
     */


    var nextSlide = function nextSlide() {
      state.currentIndex = (state.currentIndex + 1) % state.slides.length;
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };
    /**
     * Move to the previous slide
     */


    var prevSlide = function prevSlide() {
      state.currentIndex = (state.currentIndex - 1 + state.slides.length) % state.slides.length;
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };
    /**
     * Handle resize event
     */


    var handleResize = function handleResize() {
      state.currentTranslate = -state.currentIndex * getCurrentWidth();
    };

    onMounted(function () {
      goToSlide(state.currentIndex);
      window.addEventListener('resize', handleResize);
    });
    onUnmounted(function () {
      window.removeEventListener('resize', handleResize);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "carousel",
        ref: carousel,
        class: "carousel",
        onTouchstart: onTouchStart,
        onTouchmove: onTouchMove,
        onTouchend: onTouchEnd
      }, [_createElementVNode("div", {
        class: "slides",
        style: _normalizeStyle(_unref(slideStyle))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).slides, function (slide, index) {
        return _openBlock(), _createElementBlock("div", {
          class: "slide",
          key: index
        }, [_createElementVNode("div", _hoisted_1, [_createVNode(WpTestimonial, {
          data: slide
        }, null, 8, ["data"])])]);
      }), 128))], 4), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).slides, function (slide, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass(['dot', {
            active: index === _unref(state).currentIndex
          }]),
          onClick: function onClick($event) {
            return goToSlide(index);
          }
        }, null, 10, _hoisted_3);
      }), 128))])], 544);
    };
  }
};