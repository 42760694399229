import "core-js/modules/es.string.link.js";
import { useCssVars as _useCssVars } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7a0ff118"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "flex-col",
  style: {
    paddingRight: '6px'
  }
};
var _hoisted_2 = {
  class: "flex-col"
};
var _hoisted_3 = {
  class: "flex-col",
  style: {
    paddingRight: '6px'
  }
};
var _hoisted_4 = {
  class: "flex-col"
};
var _hoisted_5 = {
  class: "flex-col",
  style: {
    paddingRight: '6px'
  }
};
var _hoisted_6 = {
  class: "flex-col"
};
import { computed, ref } from 'vue';
import { Right } from '@element-plus/icons-vue';
export default {
  props: {
    link: {
      type: Object,
      required: false
    },
    clickHandler: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: true
    },
    rotateIconOnClick: {
      type: Boolean,
      default: false
    },
    textFontSize: {
      type: String,
      required: false
    },
    iconFontSize: {
      type: String,
      required: false
    }
  },
  setup: function setup(__props) {
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "37b96131": _unref(iconFontSize),
        "73571436": _unref(textFontSize),
        "61d259fe": _unref(cursor),
        "3cf373bb": _unref(color),
        "26778e2f": _unref(hoverColor)
      };
    });

    var arrowIcon = ref(null);
    /**
     * Main click handler function. If props.rotateIcon is true, rotates the arrow icon when component is clicked.
     */

    var handleClick = function handleClick() {
      props.clickHandler();

      if (props.rotateIconOnClick) {
        arrowIcon.value.$el.classList.toggle('rotated');
      }
    };
    /**
     * Changes the cursor according to whether props.disabled is set to true
     */


    var cursor = computed(function () {
      return props.disabled ? 'not-allowed' : 'pointer';
    });
    /**
     * Sets the hover color according to whether props.disabled is set to true
     */

    var hoverColor = computed(function () {
      return props.disabled ? 'var(--el-text-color-disabled)' : 'var(--el-text-color-primary)';
    });
    var color = computed(function () {
      if (props.disabled) {
        return 'var(--el-text-color-disabled)';
      }

      return props.dark ? 'var(--el-text-color-regular)' : 'var(--el-text-color-white)';
    });
    var textFontSize = computed(function () {
      return props.textFontSize || 'var(--el-font-size-base)';
    });
    var iconFontSize = computed(function () {
      return props.iconFontSize || 'var(--el-font-size-large)';
    });
    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_router_link = _resolveComponent("router-link");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

      var _component_el_dropdown = _resolveComponent("el-dropdown");

      return __props.link ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        align: "middle",
        justify: "start",
        class: "arrow-btn std-padding_line-sm"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_router_link, {
            style: {
              display: 'flex'
            },
            to: __props.link
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_1, [_createElementVNode("h5", null, [_renderSlot(_ctx.$slots, "text")])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_icon, null, {
                default: _withCtx(function () {
                  return [_createVNode(_unref(Right))];
                }),
                _: 1
              })])];
            }),
            _: 3
          }, 8, ["to"])];
        }),
        _: 3
      })) : !__props.dropdown ? (_openBlock(), _createBlock(_component_el_row, {
        key: 1,
        align: "middle",
        justify: "start",
        class: "arrow-btn std-padding_line-sm",
        onClick: handleClick
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [_createElementVNode("h5", null, [_renderSlot(_ctx.$slots, "text")])]), _createElementVNode("div", _hoisted_4, [_withDirectives(_createVNode(_component_el_icon, {
            ref_key: "arrowIcon",
            ref: arrowIcon,
            class: _normalizeClass({
              rotatable: __props.rotateIconOnClick
            })
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Right))];
            }),
            _: 1
          }, 8, ["class"]), [[_vShow, !__props.loading]])])];
        }),
        _: 3
      })) : (_openBlock(), _createBlock(_component_el_row, {
        key: 2,
        align: "middle",
        justify: "start",
        class: "std-padding_line-sm"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_dropdown, null, {
            dropdown: _withCtx(function () {
              return [_createVNode(_component_el_dropdown_menu, {
                class: "dropdown"
              }, {
                default: _withCtx(function () {
                  return [_renderSlot(_ctx.$slots, "dropdown_items")];
                }),
                _: 3
              })];
            }),
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                align: "middle",
                justify: "start",
                class: "arrow-btn label"
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("div", _hoisted_5, [_createElementVNode("h5", null, [_renderSlot(_ctx.$slots, "text")])]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createVNode(_component_el_icon, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_unref(Right))];
                    }),
                    _: 1
                  }, 512), [[_vShow, !__props.loading]])])];
                }),
                _: 3
              })];
            }),
            _: 3
          })];
        }),
        _: 3
      }));
    };
  }
};