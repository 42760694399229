import User from "@/composables/user";

export default {
    state: {
        user: {},
        lockout: null
    },
    mutations: {
        setUser (state, user) {
            state.user = { ...user };
        }
    },
    getters: {
        getUser (state) {
            return state.user;
        },
        getUserInstance () {
            return User.getInstance();
        }
    }
};