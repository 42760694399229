const slugs = require( './slugs.json');

const getLangSlugs = (key, lang = "en") => {
    return slugs[key]?.map(entry => {
        if (typeof entry === "string") {
            return entry;
        }

        return entry[lang] || entry["en"];
    }).filter(val => val) || [];
}

const makeEnDictionary = (key, lang = "en") => {
    const list = slugs[key];

    const dict = [];

    if (!list || lang === "en") {
        return dict;
    }

    list.forEach(item => {
        if (!(item && typeof item === "object")) {
            return;
        }

        if (!(item[lang] && item["en"])) {
            return;
        }

        dict[item[lang]] = item["en"];
    });

    return dict;
}

module.exports = {
    getLangSlugs,
    makeEnDictionary
}

