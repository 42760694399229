import "core-js/modules/es.function.name.js";
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, computed, inject, onUnmounted } from 'vue';
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var assignSlideNum = inject('assignSlideNum', null);
    var active_slide = inject('activeSlide', ref(-1));
    var remove_slide = inject('removeSlideNum', function () {});
    var transitionName = inject('transitionName', "fade");
    var slide_num = ref(-1);

    if (assignSlideNum) {
      assignSlideNum(slide_num, props.name);
    }

    var showSlide = computed(function () {
      return slide_num.value === active_slide.value;
    });
    onUnmounted(function () {
      if (props.name) {
        remove_slide(props.name);
      } else {
        remove_slide(slide_num.value);
      }
    });
    return function (_ctx, _cache) {
      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_Transition, {
        name: _unref(transitionName)
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createVNode(_component_el_container, {
            class: "fill-container"
          }, {
            default: _withCtx(function () {
              return [_renderSlot(_ctx.$slots, "default")];
            }),
            _: 3
          }, 512), [[_vShow, _unref(showSlide)]])];
        }),
        _: 3
      }, 8, ["name"]);
    };
  }
};