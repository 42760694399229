import { ElMessage } from 'element-plus';
import HttpClient from './HttpClient';
import WpHttpClient from './Wp';
import FastSpring from './FastSpring';
import store from '../store';
import router from '../router';
import { throttleCachebustingReloads } from "@/helpers";

export default class User {

    constructor (user = null) {

        const existing = store.getters.getUser;

        this.id = null;
        this.email = null;
        this.pi_first_name = null;
        this.pi_last_name = null;
        this.pi_street = null;
        this.pi_street2 = null;
        this.pi_state = null;
        this.pi_city = null;
        this.pi_postal_code = null;
        this.pi_country = null;
        this.pi_home_phone = null;
        this.pi_company = null;
        this.optin_marketing = null;
        this.optin_newsletter = null;
        this.survey_completed = null;
        this.perpetualProducts = null;
        this.subscriptionProducts = null;
        this.orders = null;
        this.has_account = null;
        this.is_active_subscriber = null;
        this.is_active_trialer = null;
        this.is_opted_in = null;
        this.level = null;
        this.summary = null;
        this.survey_hash = null;
        this.pi_id = null;
        this.language_preference = null;

        this.state = {
            logoutLoading: false
        };

        if (user && typeof user === 'object') {
            for (const k in user) {
                this[k] = user[k];
            }
        } else if (existing && existing.id) {
            for (const k in existing) {
                this[k] = existing[k];
            }
        }
    }

    /**
     * Authenticates an existing user
     * @param {object} credentials
     * @param {boolean} returnToCheckout
     * @returns {User}
     */
    async login (credentials, returnToCheckout = false) {
        const currentEndpoint = process.env.VUE_APP_LARAVEL_AUTH_ENDPOINT || 'sessions_1';
        const authResp = await (new HttpClient({ path: `/laravel/rest/${currentEndpoint}`, verify: false, global: true })).post({
            data: JSON.stringify(credentials),
            "cache_key": store.getters.getCacheKey
        });

        const { data } = authResp;
        
        if (!authResp?.status) {
            throw new Error('Empty response');
        } else if (authResp.status === 428 && data.token && data.email) {
            return router.push({ name: 'AccountSetup', query: {
                gt: data.token,
                e: data.email,
                rtc: returnToCheckout
            }});
        } else if (authResp.status !== 200) {
            if (authResp.status === 403 || authResp.status === 404) {
                throttleCachebustingReloads(true);
            }
            
            throw new Error(authResp.status);
        }
        
        if (!data?.id) {
            throw new Error('Invalid user');
        }
        
        this.instantiate(data);

        if (!router.currentRoute.value.meta?.requiresAuth) {
            let summaryResp = await (new HttpClient({ path: '/laravel/rest/products/summary', global: true })).post({
                data: JSON.stringify({
                    versions: FastSpring.getCbVersions()
                })
            });

            if (summaryResp.data.cbValues) {
                FastSpring.updateCbValue(summaryResp.data.cbValues);
                delete summaryResp.data.cbValues;
            }
    
            this.summary = summaryResp.data;
        }

        store.commit('setUser', this);

        return this;
    }

    /**
     * Creates a new user
     * @param {object} form
     * @returns {User}
     */
    async create (form) {
        const resp = await (new HttpClient({ path: '/laravel/rest/user', verify: false, global: true })).post({
            data: JSON.stringify({
                email: form.email,
                password: form.password,
                confirm_password: form.confirmPassword,
                first_name: form.firstName,
                last_name: form.lastName,
                optin: form.optin,
            })
        });

        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }
        
        if (!resp.data?.id) {
            throw new Error('Error creating user');
        }
        
        const { data } = resp;
        
        this.instantiate(data);

        if (!router.currentRoute.value.meta?.requiresAuth) {
            let summaryResp = await (new HttpClient({ path: '/laravel/rest/products/summary', global: true })).post({
                data: JSON.stringify({
                    versions: FastSpring.getCbVersions()
                })
            });

            if (summaryResp.data.cbValues) {
                FastSpring.updateCbValue(summaryResp.data.cbValues);
                delete summaryResp.data.cbValues;
            }
    
            this.summary = summaryResp.data;
        }
        
        store.commit('setUser', this);

        return this;
    }

    /**
     * Creates a guest account
     * @param {string} email 
     * @returns {object}
     */
    async createPartialUser (email) {
        const client = new HttpClient({ path: '/laravel/rest/partial-user', verify: false, global: true });
        let cacheKey = store.getters.getCacheKey;

        if (process.env.VUE_APP_SERVER === 'dev') {
            const wpClient = new WpHttpClient({
                namespace: '/antares/v1',
                path: '/check-cache',
            });
            const resp = await wpClient.get();
            cacheKey = resp.headers['x-wp-jscachekey'];
        }

        const payload = {
            email,
            cache_key: cacheKey
        }

        const resp = await client.post(JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 201 && resp.status !== 409) {
            throw new Error(resp.status);
        }
    }

    /**
     * Creates a guest account
     * @param {string} email 
     * @returns {object}
     */
    async updateGuest (form, guestToken, email) {
        const client = new HttpClient({ path: '/laravel/rest/guest/update', verify: false, global: true });

        const payload = {
            guest_token: guestToken,
            first_name: form.firstName,
            last_name: form.lastName,
            email,
            password: form.password
        }

        const resp = await client.patch(JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        const { data } = resp;
        
        this.instantiate(data);
        store.commit('setUser', this);

        return this;
    }

    /**
     * Captures the user's optin choice during checkout phase
     * @param {object} choice 
     */
    async captureOptinChoice (email, choice) {
        const client = new HttpClient({ path: '/laravel/rest/guest/capture-optin', verify: false, global: true });
        let cacheKey = store.getters.getCacheKey;

        const payload = {
            email,
            optin_choice: choice,
            cache_key: cacheKey
        }

        const resp = await client.patch(JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }
    }

    /**
     * Instantiates a new user object with API response data
     * @returns {object} data
     */
    instantiate (data) {
        const populate = (d) => {
            for (let prop in d) {
                if (!Object.prototype.hasOwnProperty.call(this, prop)) {
                    continue;
                }
    
                this[prop] = d[prop];
            }
        }

        populate(data);
        populate(data.personal_info);
    }

    /**
     * Updates a user model via PATCH request
     * @param {object} deltas
     * @returns {User}
     */
    async update (deltas = {}) {
        const resp = await (new HttpClient({ path: `/laravel/rest/user/${this.id}`, global: true })).patch({
            data: JSON.stringify(deltas)
        });

        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }

        if (!resp.data?.id) {
            throw new Error('Error updating user');
        }
        
        const { data } = resp;
        
        this.instantiate(data);
        store.commit('setUser', this);

        return data;
    }

    /**
     * Gets the latest user model
     * @returns {User}
     */
    async refresh () {
        const resp = await (new HttpClient({ path: '/laravel/rest/user', verify: true, global: true })).get();
        
        if (!resp?.status) {
            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }
        
        if (!resp.data?.id) {
            throw new Error('Error refreshing user');
        }
        
        this.instantiate(resp.data);
        store.commit('setUser', this);

        return this;
    }

    /**
     * Fetches a summary of the user's products and subscriptions. If the response contains a cachbuster value
     * (cbValue), calls FastSpring.updateCbValue and removes that property from the reponse before
     * committing the current user object to the store.
     * @return {object}
     */
    async fetchUserSummary () {
        let resp = await (new HttpClient({ path: '/laravel/rest/products/summary', global: true })).post({
            data: JSON.stringify({
                versions: FastSpring.getCbVersions()
            })
        });

        if (!resp?.status) {
            if (resp?.message === "canceled") {
                return {};
            }

            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }
        
        if (!resp.data) {
            throw new Error('Error fetching user product summary');
        }
        
        if (resp.data.cbValues) {
            FastSpring.updateCbValue(resp.data.cbValues);
            delete resp.data.cbValues;
        }

        this.summary = resp.data;
        
        store.commit('setUser', this);

        if (resp.data.pendingProductChanges && !store.getters.getActivelyPolling['productChange']) {
            this.handlePendingProductChanges(resp.data.pendingProductChanges);
        }

        return this.summary;
    }

    /**
     * Fetches a summary of the user's products and subscriptions. If the response contains a cachbuster value
     * (cbValue), calls FastSpring.updateCbValue and removes that property from the reponse before
     * committing the current user object to the store.
     * @param {boolean} perpetualOnly
     * @param {number} page
     * @return {object}
     */
     async fetchProducts ({ perpetualOnly = false, page = 1 }) {
        const request = { path: '/laravel/rest/user/products', query: { page } };

        if (perpetualOnly) {
            request.query.perpetual_only = true;
        }

        let resp = await (new HttpClient(request)).get();

        if (!resp?.status) {
            if (resp?.message === "canceled") {
                return {
                    total: 0,
                    current_page : 0,
                    last_page: 0,
                    per_page: 0,
                    data: {}
                };
            }

            throw new Error('Empty response');
        } else if (resp.status !== 200) {
            throw new Error(resp.status);
        }
        
        if (!resp.data) {
            throw new Error('Error fetching user product summary');
        }

        return resp.data;
    }

    /**
     * Shows a status notification for each pending product change.
     * @param {Array} changes 
     */
    handlePendingProductChanges (changes = []) {
        if (!Array.isArray(changes)) {
            return;
        }
        
        const notificationHandler = store.getters.getEventBus['globalNotify'];
        
        store.commit('resetProductChangeRequests');

        changes.forEach(v => {
            const { status, change_option, fs_sub_id } = v;

            store.commit('setProductChangeRequest', v);

            if (!notificationHandler) {
                return;
            }

            const payload = {
                title: null,
                type: null,
                message: null
            };

            const termOrProduct = change_option.is_term_increase || change_option.is_term_decrease ? 'Term' : 'Product';

            switch (status) {
                case 'requested':
                    payload.type = 'success',
                    payload.title = `${termOrProduct} Change Pending`;
                    payload.message = `Your ${termOrProduct} change is being processed. Click to view more.`;
                    break;
                case 'payment_failed':
                    payload.type = 'error';
                    payload.title = `${termOrProduct} Change Failed`;
                    payload.message = `Your ${termOrProduct} change was not fulfilled due to a payment failure. Click to view your subscription.`;
                    break;
                case 'delivered':
                    payload.type = 'success',
                    payload.title = `${termOrProduct} Change Complete`;
                    payload.message = `Your ${termOrProduct} change has been delivered. Click to view your subscription.`;
                    break;
            }

            notificationHandler().show(payload, {
                name: 'SubscriptionDetail',
                params: { id: fs_sub_id },
                query: { fs_id: true }
            });
        });
    }

    /**
     * Sets all instance properties to null, and commits the instance to the store.
     */
    async logout (message = 'Logout Successful.') {
        let type = 'success';

        try {
            this.state.logoutLoading = true;
            const resp = await (new HttpClient({ path: '/laravel/rest/logout', verify: false, global: true })).post();

            if (resp.status !== 200) {
                throw new Error(resp.status);
            }
        } catch (e) {
            message = 'Logout Error, please try again';
            type = 'error';
        } finally {
            this.id = null;
            this.email = null;
            this.pi_first_name = null;
            this.pi_last_name = null;
            this.pi_street = null;
            this.pi_street2 = null;
            this.pi_state = null;
            this.pi_city = null;
            this.pi_postal_code = null;
            this.pi_country = null;
            this.pi_home_phone = null;
            this.pi_company = null;
            this.optin_marketing = null;
            this.optin_newsletter = null;
            this.survey_completed = null;
            this.perpetualProducts = null;
            this.subscriptionProducts = null;
            this.orders = null;
            this.has_account = null;
            this.is_active_subscriber = null;
            this.is_active_trialer = null;
            this.is_opted_in = null;
            this.level = null;
            this.summary = null;
            this.survey_hash = null;
            this.pi_id = null;
            this.language_preference = null;
            
            this.state.logoutLoading = false;

            store.commit('resetProductChangeRequests');
            store.commit('setUser', this);

            const logout = ElMessage({
                onClick: () => logout.close(),
                message,
                type,
                duration: 4000,
                customClass: 'login-message',
                offset: 20
            });

            if (router.currentRoute.value.meta.requiresAuth) {
                router.push({name : "Home"});
            }
        }
    }

    /**
     * Returns a boolean indicating whether the current is a subscriber.
     * @returns {boolean}
     */
    get isSubscriber () {
        return this.summary ? this.summary.isActiveSubscriber : false;
    }

    /**
     * Returns a boolean indicating whether the current user is a trialer.
     * @returns {boolean}
     */
    get isTrialer () {
        return this.summary ? this.summary.isActiveTrialer : false;
    }

    /**
     * Returns a boolean indicating whether the current user was a trialer.
     * @returns {boolean}
     */
    get wasTrialer () {
        return this.summary ? this.summary.wasTrialer : false;
    }

    get hasAvailableTrial () {
        if (!this.summary) {
            return true;
        }
        
        return typeof this.summary.hasAvailableTrial === "number" ? this.summary.hasAvailableTrial : true;
    }

    /**
     * Returns the user's current trial subscription, if any.
     * @returns {object | null}
     */
    get trialSub () {
        if (this.isTrialer && this.summary && Array.isArray(this.summary.subscriptionSummary)) {
            return this.summary.subscriptionSummary.find(sub => sub.fs_status === 'trial');
        }
    
        return null;
    }

    /**
     * Returns a boolean indicating whether the ATU banner should be displayed to the user.
     * @returns {boolean}
     */
    get shouldSeeAtuBanner () {
        return !(this.isSubscriber || this.isTrialer);
    }

    /**
     * Returns a boolean indicating whether the current user has any user-specific upgrades available.
     * @returns {boolean}
     */
    get hasAvailableUpgrades () {
        return this.summary?.available_upgrades > 0;
    }

    /**
     * Returns the user's full name.
     * @returns {string}
     */
    get fullName () {
        return `${this.pi_first_name || ''} ${this.pi_last_name || ''}`.trim();
    }

    /**
     * Returns the user's formatted address line.
     * @returns {string | null}
     */
    get cityStatePostalLine () {
        let line = '';

        if (this.pi_city && !this.pi_state) {
            line = this.pi_city
        }

        if (!this.pi_city && this.pi_state) {
            line = this.pi_state;
        }

        if (this.pi_city && this.pi_state) {
            line = `${this.pi_city}, ${this.pi_state}`;
        }

        if (this.pi_postal) {
            line = `${line} ${this.pi_postal}`;
        }

        return line.length ? line : null;
    }
}