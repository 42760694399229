import { computed } from "vue";
import store from "../store";

export const consentStatus = computed(() => {
    return store.getters.getConsentStatus;
});

/**
 * Push cookie consent decision to vuex & datalayer
 * 
 * @param {number} decision 
 */
export const handleConsent = (decision) => {
    let boolDec = decision === 1 ? 'granted' : 'denied';
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: 'setConsent',
        ad_storage: boolDec,
        analytics_storage: boolDec,
        functionality_storage: boolDec,
        personalization_storage: boolDec,
        security_storage: boolDec
    });
    
    store.commit('setConsentStatus', decision);
}