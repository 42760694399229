import _objectSpread from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e07d83ec"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "flex-col flex-centered fullheight",
  style: {
    "padding-right": "20px"
  }
};
var _hoisted_2 = {
  class: "flex-col flex-centered",
  style: {
    "flex": "0 1 100%"
  }
};
var _hoisted_3 = {
  key: 0,
  class: "flex-col flex-centered fullheight",
  style: {
    "box-sizing": "border-box",
    "padding-right": "20px"
  }
};
var _hoisted_4 = ["href"];
var _hoisted_5 = {
  key: 2,
  class: "icon-container"
};
var _hoisted_6 = ["href"];
var _hoisted_7 = ["data-key"];
var _hoisted_8 = ["href"];
var _hoisted_9 = ["data-key"];
var _hoisted_10 = ["href"];
var _hoisted_11 = {
  class: "flex-col nav-overlay-col newsletter hidden-xs-only"
};
import { ref, computed, inject, watch, nextTick, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { CloseBold, ShoppingCart, ShoppingCartFull, UserFilled } from '@element-plus/icons-vue';
import NavMenu from "./NavMenu.json";
import NavMenuLang from "./NavMenuLang.vue";
import Newsletter from '@/components/Newsletter.vue';
import SocialLinks from '@/components/SocialLinks.vue';
import { currentBP, registerResize, removeResize } from '@/composables/windowWatcher';
import { useFsLoading } from '@/classes/FastSpring';
export default {
  props: {
    visible: Boolean
  },
  emits: ['close'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cart = inject('cart');
    var user = inject('user');
    var route = useRoute();
    var fsLoading = useFsLoading();
    var openState = computed({
      get: function get() {
        return props.visible;
      },
      set: function set() {
        handleClose();
      }
    });
    var mainMenu = ref(null);
    var subMenuRefs = ref(null);
    var activeSubMenu = ref(-1);
    var heights = ref([]);
    var copy = JSON.parse(JSON.stringify(NavMenu));
    var navMenuEntries = ref(copy.filter(function (item) {
      if (item.submenu) {
        item.submenu = item.submenu.filter(function (subitem) {
          return !subitem.hideFromOverlay;
        });
      }

      return !item.hideFromOverlay;
    }));
    var subMenus = computed(function () {
      return navMenuEntries.value.filter(function (e) {
        return e.submenu;
      });
    });
    var mainMenuHeight = computed(function () {
      var _mainMenu$value;

      var height = 0;

      if ((_mainMenu$value = mainMenu.value) !== null && _mainMenu$value !== void 0 && _mainMenu$value.clientHeight) {
        height = mainMenu.value.clientHeight;
      }

      return height;
    });
    var minMaxSubMenu = computed(function () {
      var _subMenus$value;

      var val = 0;

      if (!['xs', 'sm'].includes(currentBP) || (_subMenus$value = subMenus.value) !== null && _subMenus$value !== void 0 && _subMenus$value.length) {
        val = Math.max.apply(Math, _toConsumableArray(subMenus.value.map(function (e, i) {
          return getSubMenuHeight(i);
        })));
      }

      return "".concat(val, "px");
    });
    var lineHeight = computed(function () {
      var _mainMenu$value2, _mainMenu$value2$chil;

      var height = 0;

      if ((_mainMenu$value2 = mainMenu.value) !== null && _mainMenu$value2 !== void 0 && (_mainMenu$value2$chil = _mainMenu$value2.children[0]) !== null && _mainMenu$value2$chil !== void 0 && _mainMenu$value2$chil.clientHeight) {
        height = mainMenu.value.children[0].clientHeight;
      }

      return height;
    });

    var getSubMenuHeight = function getSubMenuHeight(index) {
      var _resizeWatchers$index;

      var height = 0;

      if ((_resizeWatchers$index = resizeWatchers[index]) !== null && _resizeWatchers$index !== void 0 && _resizeWatchers$index.height) {
        height = resizeWatchers[index].height;
      }

      return height;
    };
    /**
     * Calc offset from center of alignment for transform anchor to occur
     * Will return a value that will be added or subtracted from Y midpoint
     */


    var menuOffset = function menuOffset(index) {
      var middleOffset = 0;
      var mainMenuIndex = navMenuEntries.value.findIndex(function (e) {
        return e == subMenus.value[index];
      });
      var numEntries = navMenuEntries.value.length;

      if (numEntries <= 1) {
        // Shouldn't ever happen, but just in case
        return middleOffset;
      }

      var middleIndex = Math.ceil(numEntries / 2) - 1; // Determine if slight offset is needed; this is not needed when mainMenu entries are odd

      if (numEntries % 2 === 0) {
        middleOffset -= lineHeight.value / 2; // Account for active line in Main Menu; should center anchor on line
      }

      var numLines = middleIndex - mainMenuIndex;
      middleOffset += -1 * lineHeight.value * numLines; // Offset from center

      return middleOffset;
    };

    var contentAnchor = function contentAnchor(index) {
      var middleOffset = 0;
      var subMenuHeight = getSubMenuHeight(index);
      var transformRel = menuOffset(index);
      var transformPos = mainMenuHeight.value / 2 + transformRel; // Convert to position relative to top of container

      var closerBound = Math.min(transformPos, mainMenuHeight.value - transformPos);

      if (subMenuHeight / 2 <= closerBound) {
        // Sub Menu can sit directly on Anchor
        middleOffset = transformRel - subMenuHeight / 2;
      } else if (mainMenuHeight.value > subMenuHeight) {
        var baseOffset = transformRel - subMenuHeight / 2;
        var overflow = Math.min(mainMenuHeight.value - (transformPos + subMenuHeight / 2), transformPos);
        middleOffset = baseOffset + overflow;
      } else {
        middleOffset -= subMenuHeight / 2;
      }

      return middleOffset;
    };

    var relativeContentAnchor = function relativeContentAnchor(index) {
      var res = mainMenuHeight.value / 2 + contentAnchor(index);
      return res > 0 ? res : 0;
    };

    var transformAnchor = function transformAnchor(index) {
      return menuOffset(index) - contentAnchor(index);
    };

    var cartToggle = function cartToggle() {
      if (!fsLoading.value) {
        cart.toggleVisibility();
      }
    };

    var handleClose = function handleClose() {
      emit('close');
      setTimeout(function () {
        return activeSubMenu.value = -1;
      }, 500);
    };
    /**
     * Handles clicking on a submenu item. If submenu item's parent object contains a property 'track', then
     * then an event object is 'pushed' into the GA dataLayer object.
     * @param {object} item
     * @param {object} subMenu
     */


    var handleSubmenuClick = function handleSubmenuClick(item, subMenu) {
      if (subMenu.track) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            items: [{
              item_name: item.name
            }]
          }
        });
      }
    };

    var toggleSubmenu = function toggleSubmenu() {
      var _subMenus$value2;

      var paths = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var index = (_subMenus$value2 = subMenus.value) === null || _subMenus$value2 === void 0 ? void 0 : _subMenus$value2.indexOf(paths);

      if (activeSubMenu.value === index) {
        activeSubMenu.value = -1;
      } else {
        activeSubMenu.value = index;
      }
    };

    var resizeWatchers = reactive({});
    watch(openState, function () {
      nextTick(function () {
        if (openState.value) {
          var _iterator = _createForOfIteratorHelper(heights.value),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var entry = _step.value;
              var index = entry.getAttribute('data-key');
              resizeWatchers[index] = registerResize(entry);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          var _iterator2 = _createForOfIteratorHelper(heights.value),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _entry = _step2.value;
              removeResize(_entry);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      });
    });
    return function (_ctx, _cache) {
      var _component_el_button = _resolveComponent("el-button");

      var _component_router_link = _resolveComponent("router-link");

      var _component_el_badge = _resolveComponent("el-badge");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_container = _resolveComponent("el-container");

      var _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        "custom-class": "nav-overlay-dialog",
        modelValue: _unref(openState),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return _isRef(openState) ? openState.value = $event : null;
        }),
        "show-close": false,
        fullscreen: ""
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_container, {
            direction: "vertical",
            class: "fill-container"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                class: "topbar"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_el_row, {
                        align: "middle",
                        class: "fill-container",
                        style: {
                          "flex-wrap": "nowrap"
                        }
                      }, {
                        default: _withCtx(function () {
                          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
                            class: "white-base",
                            size: "large",
                            type: "primary",
                            plain: "",
                            onClick: handleClose,
                            icon: _unref(CloseBold),
                            circle: ""
                          }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
                            justify: "end",
                            align: "middle",
                            class: "fill-container",
                            style: {
                              "box-sizing": "border-box",
                              "flex-wrap": "nowrap"
                            }
                          }, {
                            default: _withCtx(function () {
                              return [_unref(route).name !== 'Overview' && !_unref(user).state.logoutLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_router_link, {
                                to: {
                                  name: 'Overview'
                                },
                                onClick: _cache[0] || (_cache[0] = function ($event) {
                                  return handleClose();
                                })
                              }, {
                                default: _withCtx(function () {
                                  return [_createVNode(_component_el_button, {
                                    class: "white-base",
                                    size: "large",
                                    type: "primary",
                                    plain: "",
                                    icon: _unref(UserFilled),
                                    circle: "",
                                    disabled: _unref(user).state.logoutLoading
                                  }, null, 8, ["icon", "disabled"])];
                                }),
                                _: 1
                              })])) : _createCommentVNode("", true), _unref(route).name !== 'Checkout' ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                onClick: _cache[1] || (_cache[1] = function () {
                                  return cartToggle();
                                }),
                                class: "flex-col flex-centered fullheight",
                                style: {
                                  "box-sizing": "border-box"
                                }
                              }, [_unref(cart).totalItems && typeof _unref(cart).totalItems === 'number' && _unref(cart).totalItems > 0 ? (_openBlock(), _createBlock(_component_el_badge, {
                                key: 0,
                                type: "primary",
                                value: _unref(cart).totalItems,
                                class: "cart-badge"
                              }, {
                                default: _withCtx(function () {
                                  return [_createVNode(_component_el_button, {
                                    disabled: _unref(fsLoading),
                                    loading: _unref(fsLoading),
                                    class: "white-base",
                                    size: "large",
                                    type: "primary",
                                    plain: "",
                                    icon: _unref(ShoppingCartFull),
                                    circle: ""
                                  }, null, 8, ["disabled", "loading", "icon"])];
                                }),
                                _: 1
                              }, 8, ["value"])) : (_openBlock(), _createBlock(_component_el_button, {
                                key: 1,
                                disabled: _unref(fsLoading),
                                loading: _unref(fsLoading),
                                class: "white-base",
                                size: "large",
                                type: "primary",
                                plain: "",
                                icon: _unref(ShoppingCart),
                                circle: ""
                              }, null, 8, ["disabled", "loading", "icon"]))])) : _createCommentVNode("", true)];
                            }),
                            _: 1
                          })])];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _createVNode(_component_el_row, {
                align: "middle",
                class: "nav-overlay-body"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, null, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_el_row, {
                        align: "middle",
                        class: "main-row",
                        style: _normalizeStyle({
                          'min-height': _unref(currentBP) !== 'xs' ? _unref(minMaxSubMenu) : 'auto'
                        })
                      }, {
                        default: _withCtx(function () {
                          return [_createElementVNode("div", {
                            class: "flex-col nav-overlay-col",
                            style: _normalizeStyle({
                              'min-height': _unref(currentBP) !== 'xs' ? _unref(minMaxSubMenu) : 'auto'
                            })
                          }, [_createVNode(_component_el_row, {
                            align: "middle",
                            style: {
                              "flex-wrap": "nowrap"
                            }
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("div", {
                                class: "flex-col menu-col",
                                ref_key: "mainMenu",
                                ref: mainMenu
                              }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navMenuEntries.value, function (v, index) {
                                return _openBlock(), _createBlock(_component_el_row, {
                                  class: "nav-option",
                                  key: index
                                }, {
                                  default: _withCtx(function () {
                                    return [_createVNode(_component_el_col, null, {
                                      default: _withCtx(function () {
                                        return [_createVNode(_component_el_row, {
                                          style: {
                                            "flex-wrap": "nowrap"
                                          }
                                        }, {
                                          default: _withCtx(function () {
                                            return [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                                              key: 0,
                                              href: v.externalLink,
                                              target: "_blank"
                                            }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_4)) : (_openBlock(), _createBlock(_component_router_link, {
                                              key: 1,
                                              to: v.destination,
                                              onClick: _cache[2] || (_cache[2] = function ($event) {
                                                return handleClose();
                                              })
                                            }, {
                                              default: _withCtx(function () {
                                                return [_createElementVNode("h5", null, _toDisplayString(v.name), 1)];
                                              }),
                                              _: 2
                                            }, 1032, ["to"])), v.submenu ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
                                              class: _normalizeClass(["icon-track", {
                                                active: v === _unref(subMenus)[activeSubMenu.value]
                                              }])
                                            }, [_createVNode(_component_el_icon, {
                                              onClick: function onClick() {
                                                toggleSubmenu(v);
                                              }
                                            }, {
                                              default: _withCtx(function () {
                                                return [_createVNode(_unref(CloseBold))];
                                              }),
                                              _: 2
                                            }, 1032, ["onClick"])], 2)])) : _createCommentVNode("", true)];
                                          }),
                                          _: 2
                                        }, 1024), v.submenu ? (_openBlock(), _createBlock(_component_el_row, {
                                          key: 0,
                                          class: "hidden-sm-and-up"
                                        }, {
                                          default: _withCtx(function () {
                                            return [_createVNode(_component_el_col, {
                                              class: _normalizeClass(["submenu-m", {
                                                active: _unref(subMenus)[activeSubMenu.value] === v
                                              }])
                                            }, {
                                              default: _withCtx(function () {
                                                return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.submenu, function (entry) {
                                                  return _openBlock(), _createBlock(_component_el_row, {
                                                    key: entry,
                                                    class: "subnav-option",
                                                    notranslate: v.track
                                                  }, {
                                                    default: _withCtx(function () {
                                                      return [entry.externalLink ? (_openBlock(), _createElementBlock("a", {
                                                        key: 0,
                                                        href: entry.externalLink,
                                                        target: "_blank"
                                                      }, [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)], 8, _hoisted_6)) : entry.slug ? (_openBlock(), _createBlock(_component_router_link, {
                                                        key: 1,
                                                        to: _objectSpread(_objectSpread({}, v.destination), {}, {
                                                          params: {
                                                            slug: entry.slug
                                                          }
                                                        }),
                                                        onClick: function onClick() {
                                                          handleSubmenuClick(entry, v);
                                                          handleClose();
                                                        }
                                                      }, {
                                                        default: _withCtx(function () {
                                                          return [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)];
                                                        }),
                                                        _: 2
                                                      }, 1032, ["to", "onClick"])) : entry.destination ? (_openBlock(), _createBlock(_component_router_link, {
                                                        key: 2,
                                                        to: entry.destination,
                                                        onClick: function onClick() {
                                                          handleSubmenuClick(entry, v);
                                                          handleClose();
                                                        }
                                                      }, {
                                                        default: _withCtx(function () {
                                                          return [_createElementVNode("h5", null, _toDisplayString(entry.name), 1)];
                                                        }),
                                                        _: 2
                                                      }, 1032, ["to", "onClick"])) : _createCommentVNode("", true)];
                                                    }),
                                                    _: 2
                                                  }, 1032, ["notranslate"]);
                                                }), 128))];
                                              }),
                                              _: 2
                                            }, 1032, ["class"])];
                                          }),
                                          _: 2
                                        }, 1024)) : _createCommentVNode("", true)];
                                      }),
                                      _: 2
                                    }, 1024)];
                                  }),
                                  _: 2
                                }, 1024);
                              }), 128))], 512), _createElementVNode("div", {
                                class: "menu-col submenu hidden-xs-only",
                                style: _normalizeStyle({
                                  'min-height': "".concat(_unref(mainMenuHeight), "px")
                                })
                              }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(subMenus), function (subMenu, index) {
                                return _openBlock(), _createElementBlock(_Fragment, {
                                  key: index
                                }, [_createElementVNode("div", {
                                  ref_for: true,
                                  ref: function ref(el) {
                                    heights.value[index] = el;
                                  },
                                  class: "submenu-list-outer",
                                  style: _normalizeStyle([{
                                    "position": "absolute",
                                    "visibility": "hidden !important"
                                  }, {
                                    '--anchor': "".concat(transformAnchor(index), "px"),
                                    'top': "calc(50% + ".concat(contentAnchor(index), "px)")
                                  }]),
                                  "data-key": index
                                }, [_createVNode(_component_el_col, {
                                  class: "submenu-list"
                                }, {
                                  default: _withCtx(function () {
                                    return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.submenu, function (v) {
                                      return _openBlock(), _createBlock(_component_el_row, {
                                        key: v,
                                        class: "nav-option"
                                      }, {
                                        default: _withCtx(function () {
                                          return [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: v.externalLink,
                                            target: "_blank"
                                          }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_8)) : v.slug ? (_openBlock(), _createBlock(_component_router_link, {
                                            key: 1,
                                            onClick: function onClick() {
                                              handleSubmenuClick(v, subMenu);
                                              handleClose();
                                            },
                                            to: _objectSpread(_objectSpread({}, subMenu.destination), {}, {
                                              params: {
                                                slug: v.slug
                                              }
                                            })
                                          }, {
                                            default: _withCtx(function () {
                                              return [_createElementVNode("h5", null, _toDisplayString(v.name), 1)];
                                            }),
                                            _: 2
                                          }, 1032, ["onClick", "to"])) : v.destination ? (_openBlock(), _createBlock(_component_router_link, {
                                            key: 2,
                                            onClick: function onClick() {
                                              handleSubmenuClick(v, subMenu);
                                              handleClose();
                                            },
                                            to: v.destination
                                          }, {
                                            default: _withCtx(function () {
                                              return [_createElementVNode("h5", null, _toDisplayString(v.name), 1)];
                                            }),
                                            _: 2
                                          }, 1032, ["onClick", "to"])) : _createCommentVNode("", true)];
                                        }),
                                        _: 2
                                      }, 1024);
                                    }), 128))];
                                  }),
                                  _: 2
                                }, 1024)], 12, _hoisted_7), _createVNode(_Transition, {
                                  name: "submenu"
                                }, {
                                  default: _withCtx(function () {
                                    return [_withDirectives(_createElementVNode("div", {
                                      ref_for: true,
                                      ref_key: "subMenuRefs",
                                      ref: subMenuRefs,
                                      class: "submenu-list-outer",
                                      "data-key": index,
                                      style: _normalizeStyle({
                                        '--anchor': "".concat(transformAnchor(index), "px"),
                                        '--abs-top': "".concat(contentAnchor(index), "px"),
                                        'top': "".concat(relativeContentAnchor(index), "px")
                                      })
                                    }, [_createVNode(_component_el_col, {
                                      class: "submenu-list"
                                    }, {
                                      default: _withCtx(function () {
                                        return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.submenu, function (v) {
                                          return _openBlock(), _createBlock(_component_el_row, {
                                            key: v,
                                            class: "nav-option"
                                          }, {
                                            default: _withCtx(function () {
                                              return [v.externalLink ? (_openBlock(), _createElementBlock("a", {
                                                key: 0,
                                                href: v.externalLink,
                                                target: "_blank"
                                              }, [_createElementVNode("h5", null, _toDisplayString(v.name), 1)], 8, _hoisted_10)) : v.slug ? (_openBlock(), _createBlock(_component_router_link, {
                                                key: 1,
                                                onClick: function onClick() {
                                                  handleSubmenuClick(v, subMenu);
                                                  handleClose();
                                                },
                                                to: _objectSpread(_objectSpread({}, subMenu.destination), {}, {
                                                  params: {
                                                    slug: v.slug
                                                  }
                                                })
                                              }, {
                                                default: _withCtx(function () {
                                                  return [_createElementVNode("h5", null, _toDisplayString(v.name), 1)];
                                                }),
                                                _: 2
                                              }, 1032, ["onClick", "to"])) : v.destination ? (_openBlock(), _createBlock(_component_router_link, {
                                                key: 2,
                                                onClick: function onClick() {
                                                  handleSubmenuClick(v, subMenu);
                                                  handleClose();
                                                },
                                                to: v.destination
                                              }, {
                                                default: _withCtx(function () {
                                                  return [_createElementVNode("h5", null, _toDisplayString(v.name), 1)];
                                                }),
                                                _: 2
                                              }, 1032, ["onClick", "to"])) : _createCommentVNode("", true)];
                                            }),
                                            _: 2
                                          }, 1024);
                                        }), 128))];
                                      }),
                                      _: 2
                                    }, 1024)], 12, _hoisted_9), [[_vShow, activeSubMenu.value === index]])];
                                  }),
                                  _: 2
                                }, 1024)], 64);
                              }), 128))], 4)];
                            }),
                            _: 1
                          })], 4), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_row, null, {
                            default: _withCtx(function () {
                              return [_createVNode(_component_el_col, null, {
                                default: _withCtx(function () {
                                  return [_createVNode(Newsletter, {
                                    darkmode: ""
                                  }), _createVNode(SocialLinks, {
                                    darkmode: ""
                                  })];
                                }),
                                _: 1
                              })];
                            }),
                            _: 1
                          })])];
                        }),
                        _: 1
                      }, 8, ["style"])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _createVNode(_component_el_row, {
                align: "middle",
                class: "nav-overlay-footer"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(NavMenuLang)];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};