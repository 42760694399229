import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.includes.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6233b08e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  notranslate: ""
};
var _hoisted_2 = {
  notranslate: ""
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  notranslate: ""
};
import { ref, computed, inject, watch, onMounted } from 'vue';
import { currentBP } from '@/composables/windowWatcher';
import { ArrowRightBold } from '@element-plus/icons-vue';
import { updateCurrentRoute } from '@/helpers/language';
export default {
  props: {
    alwaysDropdown: Boolean,
    colorOverride: String
  },
  setup: function setup(__props) {
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "7aa1d3a2": __props.colorOverride
      };
    });

    var localize = inject('localize'); // Allow previous languages to remain sorted as their old implementation

    var sortOrder = ["en", "fr", "es", "de", "pt", "ja", "ko", "it"];
    var enabledLangs = ref(null); // Computed

    var activeLang = computed({
      get: function get() {
        return localize.currCode.value;
      },
      set: function set(value) {
        if (localize.hasLoaded.value) {
          updateCurrentRoute(value);
        }
      }
    });
    var Language = computed(function () {
      var _enabledLangs$value, _enabledLangs$value$f;

      return (_enabledLangs$value = enabledLangs.value) === null || _enabledLangs$value === void 0 ? void 0 : (_enabledLangs$value$f = _enabledLangs$value.find(function (entry) {
        return entry.code === activeLang.value;
      })) === null || _enabledLangs$value$f === void 0 ? void 0 : _enabledLangs$value$f.name;
    }); // Methods

    /**
     * Change the page's language via Localize
     * 
     * @param code - string in the form of a Localize accepted langCode (ISO 639-1)
     */

    var handleLang = function handleLang(code) {
      activeLang.value = code;
    };

    var initialize = function initialize() {
      if (localize.hasLoaded.value && !enabledLangs.value) {
        localize.getAvailableLanguages(function (err, langs) {
          if (err) {
            console.warn("Unable to retrieve available languages", err);
            return;
          }

          enabledLangs.value = langs;
          enabledLangs.value.map(function (lang) {
            var words = lang.name.split(" ");

            if (words.length > 1) {
              // Possibly Depreciated; Convert country specific languages to <ISO-639-1>-<ISO 3166> format
              var ISO_639_1 = words[0].slice(0, 2).toUpperCase();
              var ISO_3166 = words[1].replace(/[()]/g, "").slice(0, 2).toUpperCase();
              lang.name = "".concat(ISO_639_1, "-").concat(ISO_3166);
            } else if (lang.name.length > 3) {
              // Convert language name to 3 capitalized characters
              lang.name = lang.name.slice(0, 3).toUpperCase();
            }
          });
          /**
           * Attempt to sort languages as per previous format.
           * New languages will sink to bottom of the list unless added to sortOrder array above.
           */

          enabledLangs.value = enabledLangs.value.sort(function (a, b) {
            var aInd = sortOrder.indexOf(a.code);
            var bInd = sortOrder.indexOf(b.code);
            if (aInd < 0) return 1;
            if (bInd < 0) return -1;
            return aInd - bInd;
          });
        });
      }
    }; // Hooks & Watchers
    // Attempt to populate Enabled Languages on mounted


    onMounted(initialize); // Set up watcher if Localize has yet to fully load

    if (!localize.hasLoaded.value) {
      var disableWatcher = watch(function () {
        return localize.hasLoaded.value;
      }, function (newVal, oldVal) {
        if (!oldVal && newVal !== oldVal) {
          initialize();
          disableWatcher();
        }
      });
    }

    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

      var _component_el_dropdown = _resolveComponent("el-dropdown");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_Transition, {
        name: "fade-in-place"
      }, {
        default: _withCtx(function () {
          return [enabledLangs.value && _unref(activeLang) ? (_openBlock(), _createBlock(_component_el_container, {
            key: 0
          }, {
            default: _withCtx(function () {
              return [__props.alwaysDropdown || ['xs'].includes(_unref(currentBP)) ? (_openBlock(), _createBlock(_component_el_dropdown, {
                key: 0,
                onCommand: handleLang
              }, {
                dropdown: _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(enabledLangs.value, function (lang) {
                    return _openBlock(), _createElementBlock(_Fragment, {
                      key: lang.code
                    }, [_unref(activeLang) !== lang.code ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: 0,
                      command: lang.code
                    }, {
                      default: _withCtx(function () {
                        return [_createElementVNode("h5", _hoisted_2, _toDisplayString(lang.name), 1)];
                      }),
                      _: 2
                    }, 1032, ["command"])) : _createCommentVNode("", true)], 64);
                  }), 128))];
                }),
                default: _withCtx(function () {
                  return [_createVNode(_component_el_row, {
                    align: "middle",
                    justify: "center",
                    class: "nav-overlay-lang"
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("h5", _hoisted_1, _toDisplayString(_unref(Language)), 1), _createVNode(_component_el_icon, null, {
                        default: _withCtx(function () {
                          return [_createVNode(_unref(ArrowRightBold))];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })) : (_openBlock(true), _createElementBlock(_Fragment, {
                key: 1
              }, _renderList(enabledLangs.value, function (lang) {
                return _openBlock(), _createElementBlock("div", {
                  key: lang.code,
                  class: _normalizeClass(["nav-overlay-lang", {
                    'active': _unref(activeLang) === lang.code
                  }]),
                  onClick: function onClick() {
                    return handleLang(lang.code);
                  }
                }, [_createElementVNode("h5", _hoisted_4, _toDisplayString(lang.name), 1)], 10, _hoisted_3);
              }), 128))];
            }),
            _: 1
          })) : _createCommentVNode("", true)];
        }),
        _: 1
      });
    };
  }
};