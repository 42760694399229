export default {
    state: {
        lastFootballID: null,
        cbValues: {},
        lang: "en",
        eventBus: {},
        activelyPolling: {},
        upgradeModalVisible: null,
        freebieModalVisible: null,
        artistProfileType: 'all',
        selectedTrack: null,
        consentStatus: -1,
        menuActiveState: false,
        activeMenuItemState: null,
        cacheKey: null,
        cbReloadCount: {
            count: 0,
            last: Date.now()
        },
        lastWpRoute: '/',
        postCachebustingReloadData: null,
    },
    mutations: {
        setLastFootballID ( state, status) {
            state.lastFootballID = status;
        },
        setCbValues (state, cbValues) {
            state.cbValues = cbValues;
        },
        setEventBusCallbackFunction (state, { name, cb = null }) {
            if (!cb) {
                delete state.eventBus[name];
            } else {
                state.eventBus[name] = cb;
            }
        },
        setPollingState (state, { name, active = false }) {
            if (!active) {
                delete state.activelyPolling[name];
            } else {
                state.activelyPolling[name] = active;
            }
        },
        resetPollingState (state) {
            state.activelyPolling = {};
        },
        setUpgradeModalVisibilityState (state, payload = null) {
            state.upgradeModalVisible = payload;
        },
        setFreebieModalVisibilityState (state, payload = null) {
            state.freebieModalVisible = payload;
        },
        setArtistProfileType (state, payload = 'all') {
            state.artistProfileType = payload;
        },
        setSelectedTrack (state, payload) {
            state.selectedTrack = payload;
        },
        setConsentStatus (state, payload) {
            state.consentStatus = payload;
        },
        setMenuActiveState (state, payload) {
            state.menuActiveState = payload;
        },
        setActiveMenuItemState (state, payload) {
            state.activeMenuItemState = payload;
        },
        setCacheKey (state, payload) {
            state.cacheKey = payload;
        },
        setCachebustingReloadCount (state, { count = 0, last = Date.now() }) {
            state.cbReloadCount = { count, last };
        },
        setPostCachebustingReloadData (state, payload = null) {
            state.postCachebustingReloadData = payload;
        },
        setLastWpRoute (state, payload) {
            state.lastWpRoute = payload;
        },
        setLang (state, payload) {
            state.lang = payload;
        }
    },
    getters: {
        getLastFootballID ( state ) {
            return state.lastFootballID;
        },
        getCbValues (state) {
            return state.cbValues;
        },
        getEventBus (state) {
            return state.eventBus;
        },
        getActivelyPolling (state) {
            return state.activelyPolling;
        },
        getUpgradeModalVisibilityState (state) {
            return state.upgradeModalVisible;
        },
        getFreebieModalVisibilityState (state) {
            return state.freebieModalVisible;
        },
        getArtistProfileType (state) {
            return state.artistProfileType;
        },
        getSelectedTrack (state) {
            return state.selectedTrack;
        },
        getConsentStatus (state) {
            return state.consentStatus;
        },
        getMenuActiveState (state) {
            return state.menuActiveState;
        },
        getActiveMenuItem (state) {
            return state.activeMenuItemState;
        },
        getCacheKey (state) {
            return state.cacheKey;
        },
        getCachebustingReloadCount (state) {
            return state.cbReloadCount;
        },
        getPostCachebustingReloadData (state) {
            return state.postCachebustingReloadData;
        },
        getLastWpRoute (state) {
            return state.lastWpRoute;
        },
        getLang (state) {
            return state.lang;
        }
    }
};
