export default {
    state: {
        transaction: null,
        transactionError: false,
        fsData: null,
        fsUpgradeSubstitutions: null,
        fsPaymentCancelled: false,
        fsPurchaseRecords: {},
        currencyCode: 'USD',
        fsVersion: null,
        cachedIp: null,
    },
    actions: {
        setTransaction ({ commit }, trx = null) {
            commit('setTransaction', trx);
        },
        setTransactionError ({ commit }, e) {
            commit('setTransactionError', e);
            setTimeout(() => commit('setTransactionError', null));
        },
        setFsData ({ commit }, fsData) {
            commit('setFsData', fsData);
        },
        setFsUpgradeSubstitutions ({ commit }, fsUpgradeSubstitutions) {
            commit('setFsUpgradeSubstitutions', fsUpgradeSubstitutions);
        },
        setFsPaymentCancelled ({ commit }) {
            commit('setFsPaymentCancelled', true);
            setTimeout(() => commit('setFsPaymentCancelled', false));
        }
    },
    mutations: {
        setTransaction (state, trx) {
            state.transaction = trx;
        },
        setTransactionError (state, e) {
            state.transactionError = e;
        },
        setFsData (state, fsData) {
            state.fsData = fsData;
        },
        setFsUpgradeSubstitutions (state, fsUpgradeSubstitutions) {
            state.setFsUpgradeSubstitutions = fsUpgradeSubstitutions;
        },
        setFsPaymentCancelled (state, status) {
            state.fsPaymentCancelled = status;
        },
        setFsPurchaseRecord (state, purchaseId) {
            state.fsPurchaseRecords[purchaseId] = Date.now();
        },
        unsetFsPurchaseRecord (state, purchaseId) {
            delete state.fsPurchaseRecords[purchaseId];
        },
        setCurrencyCode (state, currencyCode = 'USD') {
            state.currencyCode = currencyCode;
        },
        setFsVersion (state, version) {
            state.fsVersion = version;
        },
        setCachedIp (state, cachedIp) {
            state.cachedIp = cachedIp;
        },
    },
    getters: {
        getTransaction (state) {
            return  { ...state.transaction };
        },
        getTransactionError (state) {
            return state.transactionError;
        },
        getFsData (state) {
            return { ...state.fsData };
        },
        getFsDataObj: (state) => (path) => {
            if (!(path && typeof path === "string")) return null;

            for (let key in state.fsData) {
                if (state.fsData[key].path && state.fsData[key].path === path) {
                    return { ...state.fsData[key] };
                }
            }
        },
        getFsUpgradeSubstitutions (state) {
            return { ...state.fsUpgradeSubstitutions };
        },
        getFsPaymentCancelled (state) {
            return state.fsPaymentCancelled;
        },
        getFsPurchaseRecords (state) {
            return state.fsPurchaseRecords;
        },
        getCurrencyCode (state) {
            return state.currencyCode;
        },
        getFsVersion (state) {
            return state.fsVersion;
        },
        getCachedIp (state) {
            return state.cachedIp;
        },
    }
};