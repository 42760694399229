import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.string.includes.js";
import { useCssVars as _useCssVars } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4f978ecb"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fill-container",
  style: {
    "overflow": "clip",
    "position": "relative"
  }
};
import { reactive, provide, onMounted, onUnmounted, toRef, ref, computed } from 'vue';
import CarouselIndicators from './CarouselIndicators.vue';
import { useSwipe } from '@vueuse/core';
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons-vue';
export default {
  props: {
    interval: {
      type: Number,
      required: false,
      default: 10
    },
    initialIndex: {
      type: Number,
      required: false,
      default: 0
    },
    pauseOnHover: {
      type: Boolean,
      required: false,
      default: true
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    },
    showIndicators: Boolean,
    showArrows: Boolean,
    indicatorPos: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          bottom: '0',
          left: '50%'
        };
      },
      // Default: Bottom Center
      validator: function validator(v) {
        return "bottom" in v || "left" in v || "right" in v || "top" in v; // Positioning for its absolute position
      }
    },
    indicatorAdjustOrigin: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          vertical: '0',
          horizontal: '-50%'
        }; // center indicators on its x-axis
      },
      validator: function validator(v) {
        return v.vertical && v.horizontal;
      }
    },
    indicatorUseExtraContent: {
      type: Boolean,
      required: false,
      default: false
    },
    transitionName: {
      type: String,
      required: false,
      default: "fade"
    },
    disableSwipeGesture: Boolean,
    swipeSkipSlides: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disableLoop: Boolean
  },
  emits: ["slideChanged"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
        emits = _ref.emit;
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "3441c91a": _unref(translateBy),
        "247f9d3e": __props.indicatorPos.top,
        "6b70413e": __props.indicatorPos.left,
        "02ee47c5": __props.indicatorPos.right,
        "3fe7c9a2": __props.indicatorPos.bottom,
        "a004f6c0": __props.indicatorAdjustOrigin.horizontal,
        "00604ef2": __props.indicatorAdjustOrigin.vertical
      };
    });

    var state = reactive({
      active_slide: props.initialIndex,
      num_slides: 0,
      interval: props.interval,
      interval_id: null,
      timer: 0,
      isPaused: false,
      slideAlias: [],
      lockSlideChange: false
    });
    var carousel = ref(null);
    var triggerThreshold = ref(0);
    var translateBy = null;
    var progress = ref(0);
    var initSwipeDir = ref(null);
    var initDocumentBodyOverflow = document.body.style.overflowY;

    if (!props.disableSwipeGesture) {
      var _useSwipe = useSwipe(carousel, {
        passive: true,
        // Touch Start
        onSwipeStart: function onSwipeStart(e) {
          if (!state.isPaused) {
            state.isPaused = true;
          }

          triggerThreshold.value = carousel.value.$el.clientWidth * 0.05;
          progress.value = 0;
          initSwipeDir.value = null;
        },
        // Mid Swipe; executes when initial (internal) threshold (default 50) is met or exceeded
        onSwipe: function onSwipe() {
          /**
           * Lock behavior to one axis on swipe;
           * 
           * Locks vertical scroll when Horizontal swipe is initiated, allowing smoother behavior; does not currently work on mobile Safari
           * Should allow cleaner vertical scrolling; horizontal swipe progress should not show
           */
          if (state.num_slides <= 1) {
            return;
          }

          if (!initSwipeDir.value) {
            initSwipeDir.value = ["LEFT", "RIGHT"].includes(direction.value) ? "H" : "V";
          }

          if (initSwipeDir.value !== "H") {
            return;
          }

          document.body.style.overflowY = "hidden";

          if (!carousel.value.$el.classList.contains("swiping-active")) {
            carousel.value.$el.classList.add("swiping-active");
          }

          var canUpdate = true;

          if (props.disableLoop) {
            var swipePastStart = lengthX.value < 0 && state.active_slide === 0;
            var swipePastEnd = lengthX.value > 0 && state.active_slide === state.num_slides - 1;
            canUpdate = !(swipePastStart || swipePastEnd);
          }

          if (!canUpdate) {
            return;
          }

          var adjustedDist = Math.max(0, Math.abs(lengthX.value) - triggerThreshold.value);
          progress.value = Math.min(1.0, adjustedDist / triggerThreshold.value); // Track actual progress on swipe; distance = triggerThreshold (set above) + internal trigger (default 50)
        },
        // Executes on touch end IF swipe was detected
        onSwipeEnd: function onSwipeEnd() {
          document.body.style.overflowY = initDocumentBodyOverflow;
          carousel.value.$el.classList.remove("swiping-active");
          state.isPaused = false;
          state.timer = 0;

          if (state.num_slides <= 1) {
            return;
          }

          if (initSwipeDir.value !== "H") {
            return;
          }

          if (progress.value !== 1.0) {
            return;
          }

          if (lengthX.value > 0) {
            swipeNextSlide(true);
          } else if (lengthX.value < 0) {
            swipePrevSlide(true);
          }
        }
      }),
          lengthX = _useSwipe.lengthX,
          direction = _useSwipe.direction;

      translateBy = computed(function () {
        if (!lengthX.value || initSwipeDir.value !== "H") {
          return 0;
        }

        var dir = lengthX.value > 0 ? -1 : 1;
        return "".concat(dir * (progress.value * 2), "%");
      });
    }
    /**
     * Register Carousel Slide with optional slide name. Modifies value stored in passed in ref id.
     * @param {ref} id
     * @param {string} name 
     */


    var assignSlideNum = function assignSlideNum(id, name) {
      try {
        if (name && state.slideAlias.find(function (slide) {
          return slide.name === name;
        })) {
          throw new Error("Alias \"".concat(name, "\" already exists in the carousel"));
        }

        state.slideAlias.push({
          name: name,
          id: id
        });
        id.value = state.num_slides;
        state.num_slides++;
      } catch (e) {
        console.log(e);
      }
    };
    /**
     * Unregister slide by name or by its index
     * Currently used when Slide component Unmounts to clean up the carousel if dynamic content is registered/unregistered (i.e. v-for loops)
     * 
     * @param {string | number} val 
     */


    var removeSlideNum = function removeSlideNum(val) {
      var slide = -1;

      if (typeof val === "string") {
        try {
          var slide_obj = state.slideAlias.find(function (slide) {
            return slide.name === val;
          });

          if (!slide_obj) {
            throw new Error("Unable to remove \"".concat(val, "\"; alias not found"));
          }

          slide = slide_obj.id;
        } catch (e) {
          console.log(e);
          return;
        }
      } else if (0 <= val && val < state.num_slides) {
        slide = val;
      } else {
        console.log("\"".concat(val, "\" is out of bounds"));
        return;
      }

      if (state.active_slide >= slide) {
        state.active_slide = 0;
      }

      for (var i = slide + 1; i < state.slideAlias.length; i++) {
        state.slideAlias[i].id--;
      }

      state.slideAlias.splice(slide, 1);
      state.num_slides = state.slideAlias.length;
      state.timer = 0;
    };

    var updateVisibility = function updateVisibility() {
      state.isPaused = document.visibilityState === "hidden";
    };
    /**
     * Switch slide to specific index
     * May use a slide alias instead if known
     * 
     * optional boolean emit to allow watching for change completion
     * @param {string | number} val
     * @param {boolean} emit
     */


    var setSlideNum = function setSlideNum(val) {
      var emit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var newSlide = 0;

      if (typeof val === 'string') {
        try {
          var slide_obj = state.slideAlias.find(function (slide) {
            return slide.name === val;
          });

          if (!slide_obj) {
            throw new Error("\"".concat(val, "\" is not an alias for a slide"));
          }

          newSlide = slide_obj.id;
        } catch (e) {
          console.log(e);
        }
      } else if (0 <= val && val < state.num_slides) {
        newSlide = val;
      }

      state.active_slide = newSlide;
      state.timer = 0;

      if (emit) {
        emits('slideChanged', {
          index: state.active_slide,
          name: state.slideAlias[state.active_slide].name
        });
      }
    };
    /**
     * Used to increment slide to next neighbor
     * 
     * optional boolean emit to allow watching for change completion
     * @param {boolean} emit 
     */


    var nextSlide = function nextSlide() {
      var emit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var changeTriggered = false;

      if (!props.disableLoop) {
        state.active_slide = (state.active_slide + 1) % state.num_slides;
        changeTriggered = true;
      } else if (state.active_slide < state.num_slides - 1) {
        state.active_slide += 1;
        changeTriggered = true;
      }

      if (changeTriggered) {
        state.timer = 0;

        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };

    var swipeNextSlide = function swipeNextSlide() {
      var emit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!props.swipeSkipSlides.length) {
        nextSlide(emit);
        return;
      }

      var changeTriggered = false;
      var nextSlideIndex = state.active_slide;

      if (!props.disableLoop) {
        nextSlideIndex = (nextSlideIndex + 1) % state.num_slides;

        while (nextSlideIndex !== state.active_slide && props.swipeSkipSlides.includes(state.slideAlias[nextSlideIndex].name)) {
          nextSlideIndex = (nextSlideIndex + 1) % state.num_slides;
        }

        if (nextSlideIndex !== state.activeSlide) {
          changeTriggered = true;
        }
      } else if (nextSlideIndex + 1 < state.num_slides) {
        nextSlideIndex += 1;

        while (nextSlideIndex < state.num_slides && props.swipeSkipSlides.includes(state.slideAlias[nextSlideIndex].name)) {
          nextSlideIndex += 1;
        }

        if (nextSlideIndex !== state.num_slides) {
          changeTriggered = true;
        }
      }

      if (changeTriggered) {
        state.active_slide = nextSlideIndex;
        state.timer = 0;

        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };
    /**
     * Used to decrement slide to previous neighbor
     * 
     * optional boolean emit to allow watching for change completion
     * @param {boolean} emit
     */


    var prevSlide = function prevSlide() {
      var emit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var changeTriggered = false;

      if (!props.disableLoop) {
        state.active_slide = state.active_slide - 1 >= 0 ? state.active_slide - 1 : state.num_slides - 1;
        changeTriggered = true;
      } else if (state.active_slide > 0) {
        state.active_slide -= 1;
        changeTriggered = true;
      }

      if (changeTriggered) {
        state.timer = 0;

        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };

    var swipePrevSlide = function swipePrevSlide() {
      var emit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (!props.swipeSkipSlides.length) {
        prevSlide(emit);
        return;
      }

      var changeTriggered = false;
      var prevSlideIndex = state.active_slide;

      if (!props.disableLoop) {
        prevSlideIndex = prevSlideIndex - 1 >= 0 ? prevSlideIndex - 1 : state.num_slides - 1;

        while (prevSlideIndex !== state.active_slide && props.swipeSkipSlides.includes(state.slideAlias[prevSlideIndex].name)) {
          prevSlideIndex = prevSlideIndex - 1 >= 0 ? prevSlideIndex - 1 : state.num_slides - 1;
        }

        if (prevSlideIndex !== state.activeSlide) {
          changeTriggered = true;
        }
      } else if (prevSlideIndex - 1 >= 0) {
        prevSlideIndex -= 1;

        while (prevSlideIndex >= 0 && props.swipeSkipSlides.includes(state.slideAlias[prevSlideIndex].name)) {
          prevSlideIndex -= 1;
        }

        if (prevSlideIndex >= 0) {
          changeTriggered = true;
        }
      }

      if (changeTriggered) {
        state.active_slide = prevSlideIndex;
        state.timer = 0;

        if (emit) {
          emits('slideChanged', {
            index: state.active_slide,
            name: state.slideAlias[state.active_slide].name
          });
        }
      }
    };
    /**
     * Provide for component injection
     * Allows us to pass data to N number of Slides without needing to pass props
     */


    provide('setSlideNum', setSlideNum);
    provide('nextSlide', nextSlide);
    provide('prevSlide', prevSlide);
    provide('slideControllers', {
      setSlideNum: setSlideNum,
      nextSlide: nextSlide,
      prevSlide: prevSlide
    });
    provide('assignSlideNum', assignSlideNum); // Used when Slide mounts; any component can use this to be come a slide of this carousel

    provide('removeSlideNum', removeSlideNum); // Used when Slide unmounts; be sure to call this onUnmount to ensure "slides" get cleaned up properly

    provide('numSlides', toRef(state, 'num_slides'));
    provide('activeSlide', toRef(state, 'active_slide'));
    provide('transitionName', toRef(props, 'transitionName'));
    expose({
      setSlideNum: setSlideNum,
      nextSlide: nextSlide,
      prevSlide: prevSlide,
      "numSlides": toRef(state, 'num_slides'),
      "activeSlide": toRef(state, 'active_slide')
    });
    /**
     * Lifecycle Hooks
     */

    onMounted(function () {
      // Verifies active slide is within bounds, resets to 1st slide otherwise
      if (!(0 <= state.active_slide && state.active_slide < state.num_slides)) {
        state.active_slide = 0;
      }

      if (props.autoplay) {
        state.interval_id = setInterval(function () {
          if (!state.isPaused) {
            state.timer++;
          }

          if (state.timer >= state.interval) {
            nextSlide();
            state.timer = 0;
          }
        }, 1000);
        document.addEventListener("visibilitychange", updateVisibility);
      }
    });
    onUnmounted(function () {
      if (props.autoplay) {
        document.removeEventListener("visibilitychange", updateVisibility);
      }

      if (state.interval_id) {
        clearInterval(state.interval_id);
        state.interval_id = null;
      }
    });
    return function (_ctx, _cache) {
      var _component_el_container = _resolveComponent("el-container");

      var _component_el_icon = _resolveComponent("el-icon");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "carousel-container",
        ref_key: "carousel",
        ref: carousel,
        onMouseover: _cache[0] || (_cache[0] = function () {
          if (__props.pauseOnHover) {
            _unref(state).isPaused = true;
          }
        }),
        onMouseleave: _cache[1] || (_cache[1] = function () {
          if (__props.pauseOnHover) {
            _unref(state).isPaused = false;
          }
        })
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 512), _renderSlot(_ctx.$slots, "carousel-indicators", {}, function () {
        return [__props.showIndicators ? (_openBlock(), _createBlock(CarouselIndicators, {
          key: 0,
          class: "integrated-indicators",
          useExtraContent: __props.indicatorUseExtraContent
        }, {
          default: _withCtx(function () {
            return [_renderSlot(_ctx.$slots, "indicators-extra-content")];
          }),
          _: 3
        }, 8, ["useExtraContent"])) : _createCommentVNode("", true)];
      }), __props.showArrows ? (_openBlock(), _createBlock(_TransitionGroup, {
        key: 0,
        appear: "",
        name: "fade-in-place"
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", {
            class: "arrows",
            style: {
              "left": "15px"
            },
            onClick: prevSlide,
            key: "prev",
            "data-testid": "prevArrow"
          }, [_createVNode(_component_el_icon, {
            size: 30
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(ArrowLeftBold))];
            }),
            _: 1
          })], 512), [[_vShow, !__props.disableLoop || _unref(state).active_slide > 0]]), _withDirectives(_createElementVNode("div", {
            class: "arrows",
            style: {
              "right": "15px"
            },
            onClick: nextSlide,
            key: "next",
            "data-testid": "nextArrow"
          }, [_createVNode(_component_el_icon, {
            size: 30
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(ArrowRightBold))];
            }),
            _: 1
          })], 512), [[_vShow, !__props.disableLoop || _unref(state).active_slide < _unref(state).num_slides - 1]])];
        }),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};