const generated = require('./generator');

module.exports = [
  ...generated,
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'),
    meta: {
      sitemap: {
          ignoreRoute: true
      }
    }
  }
];