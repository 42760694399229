import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { watch, computed } from "vue";
import { compareRoutes } from '@/helpers/language';

axios.interceptors.response.use(r => {
    return r;
}, e => {
    if (e.response?.status === 401 && e.response.config.verify) {
        const user = store.getters.getUserInstance;
        
        if (user?.id && !user?.state.logoutLoading) {
            user.logout('Session expired, please log in again.');
        }
    }

    return e.response ? e.response : e;
});

export default class HttpClient {

    constructor ({ path, query = null, verify = true, global = false }) {
        let baseUrl = process.env.VUE_APP_LARAVEL_BASEURL ? process.env.VUE_APP_LARAVEL_BASEURL : window.location.protocol + '//' + window.location.hostname;

        if (!process.env.VUE_APP_LARAVEL_BASEURL && process.env.VUE_APP_LARAVEL_PORT) {
            baseUrl += process.env.VUE_APP_LARAVEL_PORT;
        }

        this.url = baseUrl + path;
        this.query = query;
        this.verify = verify;
        axios.defaults.verify = verify;

        if (typeof AbortController === "function") {
            this.controller = new AbortController();
        }

        if (!this.controller) {
            return;
        }

        if (!(this.controller.signal && this.controller.abort && typeof this.controller.abort === "function")) {
            this.controller = null; // Clear out Stub & return
            return;
        }

        // No need to instantiate watchers on global requests
        // if abort is needed, it should be manually called on request object
        if (global) {
            return;
        }

        const activeWatchers = []

        const stopWatching = () => {
            activeWatchers.forEach((abortWatch) => abortWatch());
        }

        const route = computed(() => router.currentRoute.value);
        const abortRequests = computed(() => store.getters.getAbortRequestsFlag);

        activeWatchers.push(watch(route, (to, from) => {
            if (!compareRoutes(to, from)) {
                this.abort();
                stopWatching();
            }
        }));

        activeWatchers.push(watch(abortRequests, () => {
            if (abortRequests.value) {
                this.abort();
                stopWatching();
            }
        }));
    }

    async get (config = null) {
        return await axios.get(this.buildUrl(), { signal: this.controller?.signal, ...config});
    }

    async post (payload, config = null) {
        return await axios.post(this.buildUrl(), payload, { signal: this.controller?.signal, ...config});
    }

    async put (payload, config = null) {
        return await axios.put(this.buildUrl(), payload, { signal: this.controller?.signal, ...config});
    }

    async patch (payload, config = null) {
        return await axios.patch(this.buildUrl(), payload, { signal: this.controller?.signal, ...config});
    }

    async delete (payload, config = null) {
        return await axios.delete(this.buildUrl(), payload, { signal: this.controller?.signal, ...config});
    }

    /**
     * Aborts currently running request.
     * 
     * Only available if browser supports AbortController
     */
    abort () {
        try {
            this.controller?.abort();
        } catch (e) {
            console.warn(e);
        }
    }

    buildUrl () {
        let url = new URL(this.url);

        if (this.query && Object.keys(this.query).length) {
            for (let q in this.query) {
                const val = this.query[q];

                if (typeof val !== 'boolean' && typeof val !== 'number' && !val) {
                    continue;
                }

                url.searchParams.set(q, `${val}`);
            }
        }

        return url.href;
    }
}