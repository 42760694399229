import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-12d866eb"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  href: "https://www.facebook.com/AutoTune/",
  target: "_blank"
};
var _hoisted_2 = {
  href: "https://www.tiktok.com/@antaresautotune",
  target: "_blank"
};
var _hoisted_3 = {
  href: "https://www.threads.net/@autotune",
  target: "_blank"
};
var _hoisted_4 = {
  href: "https://www.twitter.com/AntaresAudio",
  target: "_blank"
};
var _hoisted_5 = {
  href: "https://www.instagram.com/autotune/",
  target: "_blank"
};
var _hoisted_6 = {
  href: "https://www.linkedin.com/company/antares-autotune/",
  target: "_blank"
};
import { Facebook, Instagram, X, TikTok, LinkedIn, Threads } from "@/assets/SVGs/AutoTuneIconLib";
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["social", {
          darkmode: __props.darkmode
        }])
      }, [_createElementVNode("div", {
        class: _normalizeClass(["links", {
          vertical: __props.vertical
        }])
      }, [_createElementVNode("div", null, [_createElementVNode("a", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_unref(Facebook), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_unref(TikTok), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_unref(Threads), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_4, [_createElementVNode("div", null, [_createVNode(_unref(X), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_unref(Instagram), {
        class: "logo"
      })])])]), _createElementVNode("div", null, [_createElementVNode("a", _hoisted_6, [_createElementVNode("div", null, [_createVNode(_unref(LinkedIn), {
        class: "logo"
      })])])])], 2)], 2);
    };
  }
};