/* eslint-disable no-prototype-builtins */
export default class ImpactRadius {

    constructor (user) {
        this.user = user;
        this.loaded = false;
    }

    /**
     * Loads the ImpactRadius tag into the DOM, then calls identify() if a user is logged in.
     */
    loadScript () {
        window.ire_o = 'ire';

        if (typeof window.ire === 'undefined') {
            window.ire = function() {
                (window.ire.a = window.ire.a || []).push(arguments);
            }
        }

        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://utt.impactcdn.com/A2297643-f7a9-4857-b2e4-0bc4151b01311.js';

        var pos = document.getElementsByTagName('script')[0];
        pos.parentNode.insertBefore(script, pos);

        script.addEventListener('load', () => {
            this.loaded = typeof window.ire === 'function';
            
            if (this.user?.email) {
                this.identify();
            }
        });
    }

    /**
     * Creates a payload object containing the user id and a SHA-1 hash of the email address, then calls the ImpactRadius script's ire() function.
     */
    async identify () {        
        const buffer = new TextEncoder('utf-8').encode(this.user.email);
        let digest = null;
        let hashedEmail = '';

        try {
            digest = await crypto.subtle.digest('SHA-1', buffer);
            hashedEmail = Array.from(new Uint8Array(digest)).map( x => x.toString(16).padStart(2,'0') ).join('');    
        } catch (e) {
            console.warn('Cannot generate SHA-1 hash: SubtleCrypto.digest() function is only available in secure contexts (HTTPS)!');
        }
        
        const payload = {
            customerid: this.user.id,
            customeremail: hashedEmail
        };

        window.ire('identify', payload);
    }

    /**
     * Tracks conversions with ImpactRadius.
     * @param {object} data 
     * @returns 
     */
    trackSale (data) {
        if (!this.loaded) {
            return;
        }

        let trackingObject = {};
        trackingObject.orderId = data.reference;
        trackingObject.customerId = data.account;
        trackingObject.currencyCode = data.currency;
        trackingObject.items = [];
    
        if (data.coupons && data.coupons[0]) {
            trackingObject.orderPromoCode = data.coupons[0];
        }
    
        data.items.forEach(item => {
            trackingObject.items.push({
                subTotal: item.subtotalInPayoutCurrency,
                category: "digital",
                sku: item.product,
                quantity: item.quantity
            });
        });

        window.ire('trackConversion', 22598, trackingObject);
    }
}