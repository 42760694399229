import Product from './Product';

export default class CartProduct extends Product {

    constructor(path, cartProduct = {}) {
        super(path);

        for (let key in cartProduct) {
            this[key] = cartProduct[key];
        }
    }
}