import { ref, watch, computed } from "vue";
import router from "@/router";
import store from "@/store";
import { updateCurrentRoute } from "@/helpers/language";

export default class Localize {

    constructor () {
        this.hasLoaded = ref(false);
        this.currCode = ref(window.navigator.language);
        this.userLangs = ref([]);
        this.enabledLangs = ref([]);
        this.loadScriptTag();
    }

    loadScriptTag () {
        var localize = document.createElement('script');

        localize.setAttribute('id', 'localize-api');
        localize.setAttribute('src','https://global.localizecdn.com/localize.js');
        document.head.appendChild(localize);

        localize.addEventListener('load', () => {
            Object.assign(this, this.initializeScript());
            this.hasLoaded.value = true;
        });
    }

    initializeScript () {
        const key = 'jCqhFRc3pWFHP'; // Move to ENV file?
        const user = store.getters.getUser;
        const route = router.currentRoute;

        if (!window.Localize) {
            window.Localize = {};
            let localizeProps = [
                "translate",
                "untranslate",
                "phrase",
                "initialize",
                "translatePage",
                "setLanguage",
                "getLanguage",
                "getSourceLanguage",
                "detectLanguage",
                "getAvailableLanguages",
                "untranslatePage",
                "bootstrap",
                "prefetch",
                "on",
                "off",
                "hideWidget",
                "showWidget"
            ];

            localizeProps.forEach(e => {
                window.Localize[e] = function(){};
            });
        }

        // See: https://developers.localizejs.com/docs/library-api#initialize

        const args = {
            key,
            rememberLanguage: false, // When true, appears to prevent auto translate when changing language sub directories
            disableWidget: true,
            saveNewPhrasesFromSource: true, // Should help keep dictionary clean going forward; restricts target phrases to source language (EN)
            vueSafe: true,
        }

        if (user?.id && user?.language_preference) {
            args.targetLanguage = user.language_preference;
        }
        
        window.Localize.initialize(args);

        /**
         * Clear ALL Localize dictionary caches in Local Storage except for current & source languages.
         * 
         * Should fix all users' Local Storage once deployed.
         */
        window.Localize?.getAvailableLanguages((err, langs) => {
            if (err) {
                console.warn("Unable to clean Localize's initial state", err);
                return;
            }

            for (let lang of langs) {
                if (lang.code !== "en" && lang.code !== this.currCode.value) {
                    window.localStorage.removeItem(key + lang.code);
                }
            }

            this.enabledLangs.value = langs.sort((a,b) => {
                if (a.code === b.code) return 0;

                return a.code < b.code ? -1 : 1;
            });
        });

        /**
         * Retrieve the User's preferred languages from the Browser configuration.
         * Filters out unsupported languages.
         */
        window.Localize?.detectLanguage((err, languages) => {
            if (err) {
                console.warn(err);
                return;
            }

            const closestLangs = [];
            const langs = this.enabledLangs.value.map(lang => lang.code);

            languages.forEach(lang => {
                lang = lang.toLowerCase();

                if (langs.includes(lang)) {
                    closestLangs.push(lang);
                    return;
                }

                // Check if base language is supported (e.x. es for es-mx, fr for fr-ca, etc.)

                const base = lang.split("-")[0];

                if (base !== lang && langs.includes(base)) {
                    closestLangs.push(base);
                }
            });

            this.userLangs.value = closestLangs.filter((lang, index, arr) => index === arr.indexOf(lang)); // Filter for uniques
        });

        window.Localize?.on("setLanguage", (data) => {
            // Update ref and trigger Window/Tab sync
            this.currCode.value = data.to;
            store.commit('setLang', this.currCode.value);

            // Clear previous cache in Local Storage on Language changes
            window.localStorage.removeItem(key + data.from);
        });

        /**
         * Adjust current Tab/Window's language when a broadcast comes in & the current language is different; ignores broadcast if using a specific Language Sub Directory
         */
        watch(() => store.getters.getLang, (newVal, oldVal) => {
            if (!router.currentRoute.value?.meta?.lang && newVal !== oldVal && newVal !== this.currCode.value) {
                updateCurrentRoute(newVal);
            }
        });

        /**
         * Update the route for the correct language sub directory when both the router & Localize have finished intializing.
         * 
         * Attempts to correct the route in the following order:
         * 1. Language Sub Directory -> Allows URL to persist despite any other factors; if user entered the app explicitly using the sub directory, then it should always persist
         * 2. User Preference
         * 3. Browser language detected by Localize
         * 4. EN fallback
         */
        const stopAppLoadWatcher = watch (() => route.value?.meta?.appLoaded && this.hasLoaded.value, (newVal) => {
            if (newVal) {
                if (typeof stopAppLoadWatcher === "function") {
                    stopAppLoadWatcher();
                }

                if (route.value?.meta?.lang) {
                    // Do not adjust route for User's language; already using a specified language
                    return;
                }

                const targetLanguage = user?.language_preference || this.userLangs.value?.[0] || "en";
                updateCurrentRoute(targetLanguage, null, true);
            }
        });

        return window.Localize;
    }

    /**
     * Retrieve the current selected language from Localize
     * Returns a Computed Property to allow dynamic updates should a retrieval occur before Localize fully initializes
     * 
     * @returns ComputedRef
     */
    getCurrCode () {
        return computed(() => this.currCode.value);
    }

    /**
     * Retrieve the current enabled languages from Localize
     * Returns a Computed Property to allow dynamic updates to the list if accessed before Localize fully initializes
     * 
     * @returns ComputedRef
     */
    getEnabledLangs () {
        return computed(() => this.enabledLangs.value);
    }
}