import CoreLayout from '../layouts/CoreLayout.vue';
import { ref, onMounted, onBeforeUnmount, markRaw } from 'vue';
import store from '../store';
import { useRouter } from 'vue-router';
import { ElNotification } from 'element-plus';
export default {
  name: 'AccountApp',
  components: {
    CoreLayout: CoreLayout
  },
  setup: function setup() {
    var globalNotification = ref(null);
    var router = useRouter();
    /**
     * Global notificatiion handler
     * @return {object}
     */

    var globalNotify = function globalNotify() {
      var show = function show(_ref, routeDestination) {
        var _ref$duration = _ref.duration,
            duration = _ref$duration === void 0 ? 10000 : _ref$duration,
            _ref$customClass = _ref.customClass,
            customClass = _ref$customClass === void 0 ? 'notif' : _ref$customClass,
            _ref$onClick = _ref.onClick,
            onClick = _ref$onClick === void 0 ? null : _ref$onClick,
            title = _ref.title,
            type = _ref.type,
            message = _ref.message;

        if (routeDestination) {
          onClick = function onClick() {
            router.push(routeDestination);
            globalNotification.value.close();
            setTimeout(function () {
              return globalNotification.value = null;
            }, 1000);
          };
        }

        globalNotification.value = ElNotification({
          duration: duration,
          customClass: customClass,
          onClick: onClick,
          title: title,
          type: type,
          message: message
        });
      };

      var close = function close() {
        if (globalNotification.value && typeof globalNotification.value.close === 'function') {
          globalNotification.value.close();
          setTimeout(function () {
            return globalNotification.value = null;
          }, 1000);
        }
      };

      return {
        show: show,
        close: close
      };
    };

    onMounted(function () {
      store.commit('setEventBusCallbackFunction', {
        name: 'globalNotify',
        cb: markRaw(globalNotify)
      });
    });
    onBeforeUnmount(function () {
      store.commit('setEventBusCallbackFunction', {
        name: 'globalNotify',
        cb: null
      });
    });
    return {
      globalNotification: globalNotification,
      globalNotify: globalNotify
    };
  }
};